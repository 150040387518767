import React from 'react';
import {AboutHeroType} from '../types/aboutTypes';

interface IHero {
  heroData: AboutHeroType;
}

export const Hero = (props: IHero) => {
  const {heroData} = props;

  return (
    <section className="about">
      <div className="container">
        <div className="about-title">{heroData.heroTitle}</div>
        <div className="about-text" dangerouslySetInnerHTML={{__html: heroData.heroText}} />
      </div>
    </section>
  );
};
