import React from 'react';
import {BenefitsType} from '../types/coolOneTypes';
import {settings} from '../../../common';

import './BenefitsImage.scss';
import {scrollToPosition} from '../../../../utils/scrollToPosition';

interface IBenefitsData {
  benefitsData: BenefitsType;
}

export const BenefitsImage = (props: IBenefitsData) => {
  const {benefitsData} = props;

  return (
    <section
      className="benefitsImage"
      style={{backgroundImage: `url(${settings.b2cConfig.assetsURL}/${benefitsData.featuresImage})`}}
    >
      <div className="text">
        <p>{benefitsData.featuresText}</p>
        <div className="linkToBenefits" onClick={() => scrollToPosition('benefits')}>
          {benefitsData.featuresTitle}
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.1 8.6" width="13.1" height="8.6">
            <path d="M6.5,8.6c-0.4,0-0.8-0.2-1.1-0.5l-5-5.6c-0.6-0.6-0.5-1.6,0.1-2.1c0.6-0.5,1.6-0.5,2.1,0.1l3.9,4.4 l3.9-4.4c0.6-0.6,1.5-0.7,2.1-0.1c0.6,0.6,0.7,1.5,0.1,2.1l-5,5.6C7.4,8.4,7,8.6,6.5,8.6L6.5,8.6z"></path>
          </svg>
        </div>
      </div>
    </section>
  );
};
