import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {setModalIsOpened} from '../../store/app/events';
import {useRef, useState} from 'react';

import {label} from '../../utils/getLabel';
import {Field, Form, Formik} from 'formik';
import isEmail from 'validator/lib/isEmail';
import {getContent} from '../../service/httpService';
import config from '../../config/config.json';

import useOnClickOutside from '../../hooks/useOnClickOutside';

import './Modal.scss';
export const Modal = () => {
  const appStore = useStore($appStore);
  const [isFormSuccess, setIsFormSuccess] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = () => {
    setModalIsOpened(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const handleValidation = (values: any) => {
    let errors: any = {};

    if (values['fullName'].length < 4) {
      errors['fullName'] = label('error_name', appStore.language ? appStore.language : 'en');
    }

    if (!isEmail(values['email'])) {
      errors['email'] = label('error_email', appStore.language ? appStore.language : 'en');
    }
    if (values['comments'].length < 6) {
      errors['comments'] = label('error_enquiry', appStore.language ? appStore.language : 'en');
    }

    return errors;
  };

  const onSubmit = (values: any, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
    getContent(
      appStore.siteType,
      'POST',
      `content/${config.b2bConfig.appName}/coldchainform`,
      {
        fullName: {iv: values['fullName']},
        email: {iv: values['email']},
        company: {iv: values['company']},
        comments: {iv: values['comments']},
      },
      '',
    );
    setSubmitting(false);
    setTimeout(() => {
      resetForm();
    }, 400);
    setIsFormSuccess(true);
    // setContactData(data.items[0].data);
  };
  return (
    <div className={`modal  `} id="contact-modal">
      <div className="modal-bg modal-exit"></div>
      <div className="modal-container" ref={ref}>
        {appStore.language && (
          <div id="modal-html">
            <Formik
              initialValues={{fullName: '', company: '', email: '', comments: ''}}
              validate={(values) => {
                return handleValidation(values);
              }}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                onSubmit(values, setSubmitting, resetForm);
              }}
            >
              {({errors, touched, handleChange, isSubmitting}) => (
                <Form id="contact-form">
                  <div className="contact">
                    <div className="modalContainer">
                      <div className="main-title">
                        {label('contactTitle', appStore.language ? appStore.language : 'en')}
                      </div>
                      <div className="title">
                        {label('contactSubTitle', appStore.language ? appStore.language : 'en')}
                      </div>
                      {isFormSuccess && (
                        <div
                          className="success-message"
                          dangerouslySetInnerHTML={{
                            __html: label('contactThankYouMessage', appStore.language ? appStore.language : 'en'),
                          }}
                        />
                      )}
                      <div className="contact-frm">
                        <div className="frm-column">
                          <div className="frm-title">
                            {label('contactText', appStore.language ? appStore.language : 'en')}
                          </div>
                          <div className="frm-fields">
                            <div className="frm-row">
                              <label htmlFor="fullName">
                                {label('contactName', appStore.language ? appStore.language : 'en')}*
                              </label>
                              <Field type="text" className="form-input" name="fullName" id="fullName" />
                              {touched.fullName && errors.fullName && (
                                <p className="error-message">{errors.fullName}</p>
                              )}
                            </div>
                            <div className="frm-row">
                              <label htmlFor="company">
                                {label('contactCompany', appStore.language ? appStore.language : 'en')}
                              </label>
                              <Field type="text" className="form-input" name="company" id="company" />
                            </div>
                            <div className="frm-row">
                              <label htmlFor="email">
                                {label('contactEmail', appStore.language ? appStore.language : 'en')} *
                              </label>
                              <Field type="text" className="form-input" name="email" id="email" />
                              {touched.email && errors.email && <p className="error-message">{errors.email}</p>}
                            </div>
                          </div>
                        </div>
                        <div className="frm-column">
                          <div className="frm-row">
                            <label htmlFor="comments">
                              {label('contactComments', appStore.language ? appStore.language : 'en')} *
                            </label>
                            <Field as="textarea" className="form-input" name="comments" id="comments" />
                            {touched.comments && errors.comments && <p className="error-message">{errors.comments}</p>}
                          </div>
                        </div>
                        <div className="errors"></div>
                      </div>
                      <div className="errors"></div>
                      <div className="frm-button">
                        <button id="contactFrm-btn" className="btn btn-primary" type="submit" disabled={isSubmitting}>
                          {label('contactButton', appStore.language ? appStore.language : 'en')}
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
        <button className="modal-close modal-exit" onClick={() => setModalIsOpened(false)}>
          <span></span>
          <span></span>
        </button>
      </div>
    </div>
  );
};
