import './ArticleShare.scss';

interface IArticleShare {
  title: string;
  slug: string;
  url: string;
}

export const ArticleShare = (props: IArticleShare) => {
  const {title, slug, url} = props;
  return (
    <div className="articleShare">
      <span>Share</span>
      <div className="articleShare-icons">
        <a
          href={`https://www.linkedin.com/sharing/share-offsite/?url=https://${url}/cool-one/blog/post/${slug}`}
          className="articleShare-icons-item"
        >
          <svg className="icon-linkedIn">
            <use xlinkHref="#icon-linkedIn" />
          </svg>
        </a>
        <a
          href={`http://twitter.com/intent/tweet?status=${title}+https://${url}/cool-one/blog/post/${slug}`}
          className="articleShare-icons-item"
        >
          <svg className="icon-twitter">
            <use xlinkHref="#icon-twitter" />
          </svg>
        </a>
        <a
          href={`http://www.facebook.com/share.php?u=https://${url}/cool-one/blog/post/${slug}`}
          className="articleShare-icons-item"
        >
          <svg className="icon-facebook">
            <use xlinkHref="#icon-facebook" />
          </svg>
        </a>
      </div>
    </div>
  );
};
