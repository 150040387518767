import {AppState} from './types';
import {AppDomain} from './domain';
import {
  setLanguage,
  setNavIsOpen,
  setCurrentPage,
  setIsLoading,
  setTitle,
  setDescription,
  setIsMobile,
  setSiteType,
  setNavigation,
  setAuthor,
  setTag,
  setModalIsOpened,
} from './events';
const initialState: AppState = {
  languages: [{language: 'en'}, {language: 'nl'}],
  siteType: 'b2b',
  language: null,
  navIsOpen: false,
  currentPage: null,
  isLoading: false,
  siteTitle: '',
  siteDescription: '',
  isMobile: false,
  navigation: null,
  blogAuthor: null,
  blogTag: null,
  modalIsOpened: false,
};

export const $appStore = AppDomain.store<AppState>(initialState)
  .on(setLanguage, (state, value) => {
    return {
      ...state,
      language: value,
    };
  })
  .on(setModalIsOpened, (state, value) => {
    return {
      ...state,
      modalIsOpened: value,
    };
  })
  .on(setSiteType, (state, value) => {
    return {
      ...state,
      siteType: value,
    };
  })
  .on(setNavigation, (state, value) => {
    return {
      ...state,
      navigation: value,
    };
  })
  .on(setIsMobile, (state, value) => {
    return {
      ...state,
      isMobile: value,
    };
  })
  .on(setCurrentPage, (state, currentPage) => {
    return {
      ...state,
      currentPage: currentPage,
    };
  })
  .on(setNavIsOpen, (state, value) => {
    return {
      ...state,
      navIsOpen: value,
    };
  })
  .on(setIsLoading, (state, value) => {
    return {
      ...state,
      isLoading: value,
    };
  })
  .on(setTitle, (state, value) => {
    return {
      ...state,
      siteTitle: value,
    };
  })
  .on(setDescription, (state, value) => {
    return {
      ...state,
      siteDescription: value,
    };
  })
  .on(setTag, (state, value) => {
    return {
      ...state,
      blogTag: value,
    };
  })
  .on(setAuthor, (state, value) => {
    return {
      ...state,
      blogAuthor: value,
    };
  });

// $appStore.watch((v) => {
//   console.log(v);
// });

export default $appStore;
