import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../common';

import {
  BenefitsType,
  HeroType,
  ProductFeaturesType,
  MeetTheCoolOneType,
  FaqItemType,
  FaqDataType,
} from './types/coolOneTypes';

import {BenefitsImage, Features, Hero, MeetTheCoolOne, CoolOneFaq} from '.';

import './CoolOne.scss';
import {setDescription, setTitle} from '../../../store/app/events';

export const CoolOne = () => {
  const appStore = useStore($appStore);

  // const [coolOneData, setCoolOneData] = useState<CoolOneType>();
  const [heroData, setHeroData] = useState<HeroType>();
  const [benefitsData, setBenefitsData] = useState<BenefitsType>();
  const [productFeaturesData, setProductFeaturesData] = useState<ProductFeaturesType>();
  const [meetTheCoolOneData, setMeetTheCoolOneData] = useState<MeetTheCoolOneType>();
  const [faqData, setFaqData] = useState<FaqDataType>();
  useEffect(() => {
    const getCoolOne = async (language: string) => {
      const response: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2cConfig.appName}/coolone`,
        {},
        language,
      );
      const data = response.items[0].data;
      setTitle(data.metaTitle);
      setDescription(data.metaDescription);
      setHeroData({
        heroTitle: data.heroTitle,
        heroSubtitle: data.heroSubtitle,
        heroText: data.heroText,
        heroText2: data.heroText2,
        heroButtonText: data.heroButtonText,
        heroButtonLink: data.heroButtonLink,
        isContactButton: data.isContactButton,
        heroImage: data.heroImage,
      });
      setBenefitsData({
        featuresTitle: data.featuresTitle,
        featuresText: data.featuresText,
        featuresImage: data.featuresImage,
      });
      setProductFeaturesData({
        productFeaturesTitle: data.productFeaturesTitle,
        featureTexts: data.featureTexts,
        productFeaturesImage: data.productFeaturesImage,
        sizeText: data.sizeText,
        weightText: data.weightText,
        medicineChamberText: data.medicineChamberText,
      });
      setMeetTheCoolOneData({
        meetSectionTitle: data.meetSectionTitle,
        meetTitle: data.meetTitle,
        meetSubtitle: data.meetSubtitle,
        meetText: data.meetText,
        meetList: data.meetList,
        meetButtonText: data.meetButtonText,
        meetButtonLink: data.meetButtonLink,
        meetIsContactButton: data.meetIsContactButton,
        meetImage: data.meetImage,
      });

      const faqList: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2cConfig.appName}/faqitems`,
        {},
        language,
      );

      var faqItems: FaqItemType[] = [];

      if (faqList.items && faqList.items.length > 0)
        data.faq.map((item: string) => {
          const faqListItem = faqList.items.filter((faqItem: any) => faqItem.id === item);
          if (faqListItem.length > 0) {
            faqItems.push({
              title: faqListItem[0].data.title,
              text: faqListItem[0].data.text,
              isActive: faqListItem[0].data.isActive,
            });
          }
        });
      setFaqData({
        faqTitle: data.faqTitle,
        faqList: faqItems,
        faqButtonText: data.faqButtonText,
      });
    };

    if (appStore.language !== null && appStore.siteType === 'b2c') {
      getCoolOne(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);

  return (
    <div className="coolOne fadeIn">
      {/* <pre>{JSON.stringify(coolOneData, null, 2)}</pre> */}
      {heroData && <Hero heroData={heroData} />}
      {benefitsData && <BenefitsImage benefitsData={benefitsData} />}
      {productFeaturesData && <Features productFeaturesData={productFeaturesData} />}
      {meetTheCoolOneData && <MeetTheCoolOne meetTheCoolOneData={meetTheCoolOneData} />}
      {faqData && <CoolOneFaq faqData={faqData} />}
    </div>
  );
};
