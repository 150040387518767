import axios from 'axios';
import {setIsLoading} from '../store/app/events';
import config from '../config/config.json';
import {SiteType} from '../store/app/types';

const getBearerToken = (siteType: SiteType) => {
  return sessionStorage.getItem(`${siteType}_token`);
};

const setBearerToken = (token: string, siteType: SiteType) => {
  sessionStorage.setItem(`${siteType}_token`, token);
};

const clearBearerToken = (siteType: SiteType) => {
  sessionStorage.removeItem(`${siteType}_token`);
};

export const fetchBearerToken = async (siteType: SiteType) => {
  let token = getBearerToken(siteType);
  if (token) {
    return token;
  }

  const siteConfig = siteType === 'b2b' ? config.b2bConfig : config.b2cConfig;

  const data = `grant_type=client_credentials&scope=squidex-api&client_id=${siteConfig.clientId}&client_secret=${siteConfig.clientSecret}`;

  const response = await axios(`${config.apiURL}/identity-server/connect/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data: data,
  });

  if (response.statusText !== 'OK') {
    throw new Error(`Failed to retrieve token, got ${response.statusText}`);
  }

  const json = await response.data;

  token = json.access_token;

  token && setBearerToken(token, siteType);

  return token;
};

export const getContent = async (
  siteType: SiteType,
  method: 'POST' | 'GET' | 'PUT',
  url: string,
  data: any,
  language: string,
) => {
  // Fetch the bearer token.

  setIsLoading(true);
  const token = await fetchBearerToken(siteType);

  const retry = true;
  let rdata: [] | null = null;

  if (token) {
    await axios(`${config.apiURL}/api/${url}`, {
      method: method,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        'X-Languages': language,
        'X-Flatten': true,
      },
      data: data ? JSON.stringify(data) : null,
    })
      .then((response) => {
        rdata = response.data;
        setIsLoading(false);
        // setTimeout(() => {
        //   // setIsLoading(false);
        // }, 300);

        return response.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          clearBearerToken(siteType);
          // if (retry) {
          //   return getContent(method, url, data, language);
          // }
        }
      });
    return rdata;
  }
};

// function buildUrl(url: any) {
//   if (url.length > 0 && url.startsWith('/')) {
//     url = url.substr(1);
//   }

//   const result = `${config.apiUrl}/${url}`;

//   return result;
// }
