import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../common';
import {ContactData} from './types/contactTypes';

import './Contact.scss';
import {ContactForm} from '.';
import {setTitle, setDescription} from '../../../store/app/events';

export const Contact = () => {
  const appStore = useStore($appStore);
  const [contactData, setContactData] = useState<ContactData>();
  useEffect(() => {
    const getContact = async (language: string) => {
      const data: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2cConfig.appName}/contactpage`,
        {},
        language,
      );
      setContactData(data.items[0].data);
      setTitle(data.items[0].data.metaTitle);
      setDescription(data.items[0].data.metaDescription);
    };

    if (appStore.language !== null && appStore.siteType === 'b2c') {
      getContact(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);

  return (
    <div className="contactPage fadeIn">
      {contactData && !appStore.isLoading && (
        <div className="info-main-container">
          <h1 className="info-title">{contactData.title}</h1>
          <div className="main-info">
            <div className="contact-info-cont">
              <div className="contact-image">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2452.031102268214!2d4.3396868159547575!3d52.07916367973314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c5b710570c3551%3A0x9bae47cd3d49dec8!2sThe%20Hague%20Tech!5e0!3m2!1snl!2snl!4v1603103497604!5m2!1snl!2snl"
                  width="350"
                  height="300"
                  aria-hidden="false"
                  title="Meds2Go"
                  className="fadeIn"
                ></iframe>
              </div>
              <div className="contact-details">
                <h3>{contactData.contactInfoTitle}</h3>
                <div className="contact-infos">
                  <div
                    className="contact-info-left"
                    dangerouslySetInnerHTML={{__html: contactData.contactInfoText}}
                  ></div>
                  <div className="contact-info-right">
                    <div className="contact-email">
                      <strong>Email </strong>
                      <a href="mailto:orders@meds2go.nl">{contactData.email}</a>
                    </div>
                    <div className="contact-social">
                      <p>{contactData.socialText}</p>
                      <div className="contact-social-list">
                        <a
                          href="https://www.facebook.com/Meds2Gotravel/"
                          target="_blank"
                          className="contact-facebook"
                          rel="noreferrer"
                          title="Meds2Go - Instagram"
                        >
                          <svg className="contact-facebook">
                            <use xlinkHref="#contact-facebook" />
                          </svg>
                        </a>
                        <a
                          href="https://www.instagram.com/meds_to_go/"
                          target="_blank"
                          className="contact-instagram"
                          rel="noreferrer"
                          title="Meds2Go - Facebook"
                        >
                          <svg className="contact-instagram">
                            <use xlinkHref="#contact-instagram" />
                          </svg>
                        </a>
                        <a
                          href="https://nl.linkedin.com/company/meds2go"
                          target="_blank"
                          className="contact-linkedin"
                          rel="noreferrer"
                          title="Meds2Go - LinkedIn"
                        >
                          <svg className="contact-linkedin">
                            <use xlinkHref="#contact-linkedin" />
                          </svg>
                        </a>
                        <a
                          href="https://twitter.com/Meds2Go_info"
                          target="_blank"
                          className="contact-twitter"
                          rel="noreferrer"
                          title="Meds2Go - Twitter"
                        >
                          <svg className="contact-twitter">
                            <use xlinkHref="#contact-twitter" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContactForm contactData={contactData} />
        </div>
      )}
    </div>
  );
};
