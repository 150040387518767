import labels from '../config/labels.json';

const getLabelEn = labels.en.reduce((acc: any, label: any) => {
  Object.keys(label).forEach((key) => {
    acc[key] = label[key];
  });
  return acc;
}, {});

const getLabelNl = labels.nl.reduce((acc: any, label: any) => {
  Object.keys(label).forEach((key) => {
    acc[key] = label[key];
  });
  return acc;
}, {});

export const label = (enumString: string, lang: string) => {
  if (lang === 'nl') {
    if (getLabelNl[enumString]) {
      return getLabelNl[enumString];
    } else {
      return enumString;
    }
  } else {
    if (getLabelEn[enumString]) {
      return getLabelEn[enumString];
    } else {
      return enumString;
    }
  }
};
