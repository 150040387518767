import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useStore, $appStore, getContent, settings} from '../../common';

import './Page.scss';

export const Page = () => {
  const {slug} = useParams();
  // const {data, error} = useAxios(`${config.apiURL}/pages/${slug}`, 'GET', null, slug);

  return (
    <div className="page">
      {/* {data && slug && (
        <div className="container">
          <div className="inner">
            <h1>{data.content.title} </h1>
            <div className="text" dangerouslySetInnerHTML={{__html: data.content.text}} />
          </div>
        </div>
      )} */}
    </div>
  );
};
