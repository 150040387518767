import {Link} from 'react-router-dom';
import {ColdChainTeaserType} from '../types/coldChainTypes';
import {setModalIsOpened} from '../../../../store/app/events';

interface ITeasers {
  teasersData: ColdChainTeaserType[];
}

export const Teasers = (props: ITeasers) => {
  const {teasersData} = props;
  return (
    <section className="cold-chain-teasers">
      <div className="container">
        <div className="teaser-boxes" id="cold-chain">
          {teasersData.length > 0 &&
            teasersData.map((item: ColdChainTeaserType, i: number) => (
              <div className="teaser-box" key={i}>
                <div className="teaser-title">{item.title}</div>
                <div className="teaser-text">{item.text}</div>
                <div className="teaser-button">
                  {!item.isContactButton && item.buttonLink ? (
                    <Link to={item.buttonLink} className="btn btn-secondary">
                      {item.buttonText}
                    </Link>
                  ) : (
                    <div className="btn btn-secondary open-contact" onClick={() => setModalIsOpened(true)}>
                      {item.buttonText}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
};
