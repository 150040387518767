import React from 'react';
import {CoolingSolutionsHeroType} from '../types/coolingSolutionsTypes';
import {settings} from '../../../common';
import {setModalIsOpened} from '../../../../store/app/events';

interface IHero {
  heroData: CoolingSolutionsHeroType;
}

export const Hero = (props: IHero) => {
  const {heroData} = props;

  return (
    <section className="tech-hero">
      <div className="tech-hero-title">{heroData.heroTitle}</div>
      <div className="container">
        <div className="tech-hero-text">{heroData.heroText}</div>
        <div className="tech-hero-hero-image fadeIn">
          <img src={`${settings.assetsURL}/${heroData.heroImages[0]}`} alt={heroData.heroTitle} />
        </div>
        <div className="boxes-tech">
          {heroData.heroBoxes.map((item: any, i: number) => (
            <div className="box" key={i}>
              <div className="title">{item.heroBoxTitle}</div>
              <div className="text">{item.heroBoxText}</div>
            </div>
          ))}
        </div>
        <div className="tech-hero-button">
          {!heroData.isContactButton ? (
            <a href="{heroData.buttonLink}" className="btn btn-secondary">
              {heroData.buttonText}
            </a>
          ) : (
            <div
              className="btn btn-secondary open-contact"
              data-modal="contact-modal"
              onClick={() => setModalIsOpened(true)}
            >
              {heroData.buttonText}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
