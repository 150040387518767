import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useStore, $appStore, getContent, settings} from '../../common';
import {ArticleType} from '../Blog/types/blogTypes';

import {ArticleAuthor, ArticleDate, ArticleShare, ArticleTags} from '../Blog';

import './BlogDetails.scss';
import {setTitle, setDescription} from '../../../store/app/events';

export const BlogDetails = () => {
  const {slug} = useParams();
  const appStore = useStore($appStore);
  const [article, setArticle] = useState<ArticleType>();
  const navigate = useNavigate();

  useEffect(() => {
    const getBlogDetails = async (language: string, slug: string) => {
      const data: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2cConfig.appName}/blogitems/?q={filter:{path: "data.slug.en",op:"eq",value:"${slug}"}}`,
        {},
        language,
      );
      setArticle(data.items[0].data);
      setTitle(data.items[0].data.metaTitle);
      setDescription(data.items[0].data.metaDescription);
    };

    if (appStore.language !== null && appStore.siteType === 'b2c' && slug) {
      getBlogDetails(appStore.language, slug);
    }
  }, [appStore.language, appStore.siteType, slug]);
  return (
    <div className="blog blogDetails fadeIn">
      <div className="container">
        <div className="blog-header">
          <div className="blog-header-title"></div>
          <div className="blog-header-text" />
        </div>
      </div>
      {article && !appStore.isLoading && (
        <div className="blog-article">
          <div className="container">
            <div className="blog-article-top">
              <div className="header">
                <ArticleDate date={article.date} />
                <div className="backToBlog" onClick={() => navigate(-1)}>
                  Back to blog
                </div>
              </div>
              <div className="title">{article.title}</div>
              <div className="details">
                <ArticleAuthor author={article.author} isDetail={true} />
                <ArticleTags tags={article.tags} isDetail={true} />
                <ArticleShare
                  title={article.title}
                  slug={article.slug}
                  url={settings.siteUrl[appStore.language === 'en' ? 'en' : 'nl']}
                />
              </div>
            </div>
            <div className="blog-article-image fadeIn">
              <img src={`${settings.b2cConfig.assetsURL}/${article.image}?width=1300&quality=90`} alt={article.title} />
            </div>
            <div className="text" dangerouslySetInnerHTML={{__html: article.description}} />
          </div>
        </div>
      )}
    </div>
  );
};
