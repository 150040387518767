import React from 'react';
import {ColdChainHeroType} from '../types/coldChainTypes';
import {settings} from '../../../common';
import {scrollToPosition} from '../../../../utils/scrollToPosition';
interface IHero {
  heroData: ColdChainHeroType;
}

export const Hero = (props: IHero) => {
  const {heroData} = props;

  return (
    <section className="cold-chain-hero">
      <div className="cold-chain-hero-title">{heroData.heroTitle}</div>
      <div className="container">
        <div className="cold-chain-hero-subtitle">{heroData.heroSubtitle}</div>
        {!heroData.videoID ? (
          <div className="cold-chain-hero-image fadeIn">
            <img src={`${settings.assetsURL}/${heroData.heroImages[0]}`} alt={heroData.heroTitle} />
          </div>
        ) : (
          <div className="cold-chain-hero-video">
            <iframe
              src={`https://www.youtube.com/embed/${heroData.videoID}?autoplay=1&modestbranding=1&showinfo=0&rel=0&color=white&controls=0`}
              className="yt-video"
              title="heroData.heroTitle"
            />
          </div>
        )}

        <div className="cold-chain-hero-text">{heroData.heroText}</div>
        <div className="cold-chain-hero-button">
          <div className="scroll-to btn btn-secondary" onClick={() => scrollToPosition('cold-chain')}>
            {heroData.buttonText}
          </div>
        </div>
      </div>
    </section>
  );
};
