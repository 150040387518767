import React from 'react';
import {Link} from 'react-router-dom';
import {MarketsTeaserType, MarketsHeroType} from '../types/marketsTypes';
import {settings} from '../../../common';
import {setModalIsOpened} from '../../../../store/app/events';

interface ITeasers {
  teasersData: MarketsTeaserType[];
  heroData: MarketsHeroType;
}

export const Teasers = (props: ITeasers) => {
  const {teasersData, heroData} = props;
  return (
    <section className="markets">
      <div className="markets-title">{heroData.heroTitle}</div>
      <div className="container">
        <div className="markets-text">{heroData.heroText}</div>
        <div className="market-boxes">
          {teasersData.map((item: any, i: number) => (
            <div className="market-box fadeIn" style={{backgroundImage: `url(${settings.assetsURL}/${item.image[0]})`}}>
              <div className="divider shadow"></div>
              <img src={`${settings.assetsURL}/${item.image[0]}`} className="market-box-layer_bottom" alt="" />
              <div className="market-box-content">
                <div className="market-box-layer_top market-box-layer_slideleft">
                  <div className="market-box-text">
                    <div className="title">{item.title}</div>
                    <div className="text">
                      <div dangerouslySetInnerHTML={{__html: item.text}} />
                      <div className="marketbox-button">
                        {!item.isContactButton ? (
                          <Link to={item.buttonLink} className="btn btn-2">
                            {item.buttonText}
                          </Link>
                        ) : (
                          <div className="btn btn-2 open-contact" onClick={() => setModalIsOpened(true)}>
                            {item.buttonText}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
