import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../common';
import {FaqItemType, FaqDataType} from '../CoolOne/types/coolOneTypes';
import {FaqList} from './';

import './FaqPage.scss';
import {setDescription, setTitle} from '../../../store/app/events';

export const FaqPage = () => {
  const appStore = useStore($appStore);

  // const [coolOneData, setCoolOneData] = useState<CoolOneType>();
  const [faqData, setFaqData] = useState<FaqDataType>();
  useEffect(() => {
    const getFaq = async (language: string) => {
      const response: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2cConfig.appName}/faq`,
        {},
        language,
      );
      const data = response.items[0].data;
      setTitle(response.items[0].data.metaTitle);
      setDescription(response.items[0].data.metaDescription);

      const faqList: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2cConfig.appName}/faqitems`,
        {},
        language,
      );

      var faqItems: FaqItemType[] = [];

      if (response.items[0].data.faqItems && response.items[0].data.faqItems.length > 0)
        response.items[0].data.faqItems.map((item: string) => {
          const faqListItem = faqList.items.filter((faqItem: any) => faqItem.id === item);
          if (faqListItem.length > 0) {
            faqItems.push({
              title: faqListItem[0].data.title,
              text: faqListItem[0].data.text,
              isActive: faqListItem[0].data.isActive,
            });
          }
        });
      setFaqData({
        faqTitle: data.title,
        faqList: faqItems,
      });
    };

    if (appStore.language !== null && appStore.siteType === 'b2c') {
      getFaq(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);

  return (
    <div className="faqPage fadeIn">
      {faqData && (
        <div className="container">
          <div className="faqPage-title">{faqData.faqTitle}</div>
          <FaqList faqData={faqData} />
        </div>
      )}
    </div>
  );
};
