import React, {useState} from 'react';
import {FaqDataType, FaqItemType} from '../types/faqPageTypes';

import './FaqList.scss';

interface IFaqList {
  faqData: FaqDataType;
}

export const FaqList = (props: IFaqList) => {
  const {faqData} = props;
  const [activeItem, setActiveItem] = useState<number | null>(null);

  return (
    <div className="faq-list">
      {faqData.faqList.map((item: FaqItemType, i: number) => (
        <div
          key={i}
          className={`faq-list-item ${activeItem === i ? 'isActive' : ''}`}
          onClick={() => setActiveItem(activeItem === i ? null : i)}
        >
          <div className="faq-list-item-title">
            <div className="qa">{item.title}</div>
            <div className="qa-arrow"></div>
          </div>
          <div className="faq-list-item-text" dangerouslySetInnerHTML={{__html: item.text}} />
        </div>
      ))}
    </div>
  );
};
