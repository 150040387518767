import {useEffect, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {useStore} from 'effector-react';
import $appStore from '../../store/app/store';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {Loader} from '../Loader';
import {useNavigate} from 'react-router-dom';
import {Modal, Version} from '../';
import {setIsLoading, setLanguage, setLanguages, setNavigation, setSiteType} from '../../store/app/events';
import {getContent} from '../../service/httpService';
import config from '../../config/config.json';
import './Layout.scss';
import {SiteType} from '../../store/app/types';

export const Layout = () => {
  const appStore = useStore($appStore);
  const navigator = useNavigate();
  // const [modalIsOpened, setModalIsOpened] = useState(false);

  // useEffect(() => {
  //   if (!modalIsOpened && !sessionStorage.getItem('downloadModal')) {
  //     setModalIsOpened(true);
  //   } else {
  //     // setModalIsOpened(false);
  //   }
  // }, [modalIsOpened]);
  useEffect(() => {
    const getLanguages = async () => {
      const data: any = await getContent(appStore.siteType, 'GET', `apps/${config.appName}/languages`, null, '');
      const defaultLanguage = data.items.filter((e: any) => e.isMaster);
      setLanguage(defaultLanguage ? defaultLanguage[0].lang2Code : 'en');
      setIsLoading(false);
    };
    if (appStore.languages === null) {
      getLanguages();
    } else {
      if (appStore.language) {
        setLanguage(appStore.language);
      } else {
        setLanguage('en');
      }
    }
  }, [appStore.languages, appStore.language, appStore.siteType]);

  useEffect(() => {
    const pathArray = window.location.pathname.split('/');

    if (pathArray[1] === 'cool-one') {
      setSiteType('b2c');
    } else {
      setSiteType('b2b');
    }
  }, [window.location.pathname]);

  useEffect(() => {
    const getNavigation = async (language: string, siteType: SiteType) => {
      const data: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${siteType === 'b2b' ? config.b2bConfig.appName : config.b2cConfig.appName}/navigation`,
        null,
        language,
      );
      // setLanguage(defaultLanguage ? defaultLanguage[0].lang2Code : 'en');
      setNavigation({
        headerMenu: data.items[0].data.headerMenu,
        footerB2cMenu: data.items[0].data.footerB2cMenu,
        footerMenu: data.items[0].data.footerMenu,
        legalMenu: data.items[0].data.legalMenu,
      });
    };
    if (appStore.language) {
      getNavigation(appStore.language, appStore.siteType);
    }
  }, [appStore.siteType, appStore.language]);

  return (
    <div
      className={`layout ${appStore.navIsOpen ? 'navIsOpen' : ''} ${appStore.isMobile ? 'isMobile' : ''} ${
        appStore.isLoading ? 'loading' : ''
      } ${appStore.isMobile ? 'isMobile' : ''}`}
    >
      <Header />
      <div className={`main ${appStore.isLoading ? ' fadeIn' : ''}`}>
        <Outlet />
      </div>
      <Footer />
      {appStore.isLoading && <Loader />}
      {appStore.modalIsOpened && <Modal />}
      <Version />
    </div>
  );
};
