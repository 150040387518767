import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../common';

import {Hero, Teasers} from './';
import {CoolingSolutionsHeroType, CoolingSolutionsTeaserType} from './types/coolingSolutionsTypes';
import './CoolingSolutions.scss';
import {setTitle, setDescription} from '../../../store/app/events';

export const CoolingSolutions = () => {
  const appStore = useStore($appStore);

  const [heroData, setHeroData] = useState<CoolingSolutionsHeroType>();
  const [teasersData, setTeasersData] = useState<CoolingSolutionsTeaserType[]>();
  useEffect(() => {
    const getAbout = async (language: string) => {
      const data: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2bConfig.appName}/coolingsolutions`,
        {},
        language,
      );
      setHeroData({
        heroTitle: data.items[0].data.heroTitle,
        heroText: data.items[0].data.heroText,
        isContactButton: data.items[0].data.isContactButton,
        buttonText: data.items[0].data.buttonText,
        buttonLink: data.items[0].data.buttonLink,
        heroImages: data.items[0].data.heroImages,
        heroBoxes: data.items[0].data.heroBoxes,
      });
      setTeasersData(data.items[0].data.teasers);
      setTitle(data.items[0].data.metaTitle);
      setDescription(data.items[0].data.metaDescription);
    };
    if (appStore.language !== null && appStore.siteType === 'b2b') {
      getAbout(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);

  return (
    <div className="aboutPage fadeIn">
      {heroData && !appStore.isLoading && <Hero heroData={heroData} />}
      {teasersData && !appStore.isLoading && <Teasers teasersData={teasersData} />}
    </div>
  );
};
