import React, {useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../../common';

import {Formik, Form, Field} from 'formik';
import isEmail from 'validator/lib/isEmail';

import {ContactData} from '../types/contactTypes';
import {label} from '../../../../utils/getLabel';

interface IContactForm {
  contactData: ContactData;
}

export const ContactForm = (props: IContactForm) => {
  const {contactData} = props;
  const appStore = useStore($appStore);

  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const handleValidation = (values: any) => {
    let errors: any = {};

    if (values['firstName'].length < 4) {
      errors['firstName'] = label('error_name', appStore.language ? appStore.language : 'en');
    }
    if (values['lastName'].length < 4) {
      errors['lastName'] = label('error_lname', appStore.language ? appStore.language : 'en');
    }
    if (!isEmail(values['email'])) {
      errors['email'] = label('error_email', appStore.language ? appStore.language : 'en');
    }
    if (values['message'].length < 6) {
      errors['message'] = label('error_enquiry', appStore.language ? appStore.language : 'en');
    }

    return errors;
  };

  const onSubmit = (values: any, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
    getContent(
      appStore.siteType,
      'POST',
      `content/${settings.b2cConfig.appName}/contactform`,
      {
        firstName: {iv: values['firstName']},
        lastName: {iv: values['lastName']},
        email: {iv: values['email']},
        phone: {iv: values['phone']},
        address: {iv: values['address']},
        message: {iv: values['message']},
        date: {iv: new Date()},
      },
      '',
    );
    setSubmitting(false);
    setTimeout(() => {
      resetForm();
      setIsFormSuccess(true);
    }, 400);
    // setContactData(data.items[0].data);
  };

  return (
    <div className="info-form">
      {isFormSuccess && (
        <div className="ContactForm-success">
          <div className="text" dangerouslySetInnerHTML={{__html: contactData.thankYouMessage}} />
        </div>
      )}
      {!isFormSuccess && (
        <Formik
          initialValues={{firstName: '', lastName: '', phone: '', address: '', message: '', email: ''}}
          validate={(values) => {
            return handleValidation(values);
          }}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            onSubmit(values, setSubmitting, resetForm);
          }}
        >
          {({errors, touched, handleChange, isSubmitting}) => (
            <Form id="contact-form" className="form -pt2">
              <div className="form-header">
                <h3>{contactData.formTitle}</h3>
                <h4>Mandatory fields (*)</h4>
              </div>
              <div className="contact-fields">
                <div className="required col-50">
                  <label htmlFor="input-name">
                    {contactData.firstName} <span>(*)</span>
                  </label>
                  <Field type="text" className="form-input" name="firstName" id="firstName" />
                  {touched.firstName && errors.firstName && <p className="error-message">{errors.firstName}</p>}
                </div>
                <div className="required col-50">
                  <label htmlFor="input-lname">
                    {contactData.lastName} <span>(*)</span>
                  </label>
                  <Field type="text" className="form-input" name="lastName" id="lastName" />
                  {touched.lastName && errors.lastName && <p className="error-message">{errors.lastName}</p>}
                </div>
                <div className="required col-50">
                  <label htmlFor="input-email">
                    Email <span>(*)</span>
                  </label>
                  <Field type="text" className="form-input" name="email" id="email" />
                  {touched.email && errors.email && <p className="error-message">{errors.email}</p>}
                </div>
                <div className="required col-50">
                  <label htmlFor="input-telephone">{contactData.phone}</label>
                  <Field type="text" className="form-input" name="phone" id="phone" />
                </div>
                <div className="required col-100">
                  <label htmlFor="input-address">{contactData.address}</label>
                  <Field type="text" className="form-input" name="address" id="address" />
                </div>
                <div className="required col-100">
                  <label htmlFor="input-message">{contactData.message}</label>
                  <Field as="textarea" name="message" id="message" className="form-input" />
                  {touched.message && errors.message && <p className="error-message">{errors.message}</p>}
                </div>

                <div className="buttons">
                  <button className="" type="submit" disabled={isSubmitting}>
                    <div className="gm-btn gm-btn-primary gm-btn-hero">{contactData.buttonText}</div>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
