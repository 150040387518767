import {useNavigate} from 'react-router-dom';
import {setAuthor, setTag} from '../../../../store/app/events';
import './ArticleAuthor.scss';

interface IArticleAuthor {
  author: string;
  isDetail: boolean;
}

export const ArticleAuthor = (props: IArticleAuthor) => {
  const {author, isDetail} = props;
  const navigate = useNavigate();

  return (
    <div className="articleAuthor">
      BY{' '}
      <div
        className="name"
        onClick={() => {
          setAuthor(author);
          setTag(null);
          isDetail && navigate(-1);
        }}
      >
        {author}
      </div>
    </div>
  );
};
