import React from 'react';
import {Link} from 'react-router-dom';
import {settings, useStore, $appStore} from '../../../common';
import {PostFlatDataType} from '../types/blogTypes';

import {ArticleAuthor, ArticleDate, ArticleShare, ArticleTags} from '..';

import './FeaturedArticle.scss';
interface IFeaturedArticle {
  featuredArticle: PostFlatDataType;
}

export const FeaturedArticle = (props: IFeaturedArticle) => {
  const {featuredArticle} = props;
  const appStore = useStore($appStore);

  return (
    <article className="featuredArticle fadeIn">
      <Link to={`/cool-one/blog/post/${featuredArticle.slug}`} className="featuredArticle-image fadeIn">
        <img src={`${settings.b2cConfig.assetsURL}/${featuredArticle.image[0].slug}?width=400&quality=90`} alt="" />
      </Link>
      <div className="featuredArticle-content">
        <div className="featuredArticle-body">
          <ArticleDate date={featuredArticle.date} />
          <Link to={`/cool-one/blog/post/${featuredArticle.slug}`} className="featuredArticle-title">
            {featuredArticle.title}
          </Link>
          <div className="featuredArticle-short" dangerouslySetInnerHTML={{__html: featuredArticle.shortDescription}} />
        </div>
        <div className="featuredArticle-footer">
          <div className="featuredArticle-footer-left">
            <ArticleAuthor author={featuredArticle.author} isDetail={false} />
            <ArticleTags tags={featuredArticle.tags} isDetail={false} />
          </div>
          {appStore.language !== null && (
            <ArticleShare
              title={featuredArticle.title}
              slug={featuredArticle.slug}
              url={settings.siteUrl[appStore.language === 'en' ? 'en' : 'nl']}
            />
          )}
        </div>
      </div>
    </article>
  );
};
