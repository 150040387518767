import {useNavigate} from 'react-router-dom';
import {setAuthor, setTag} from '../../../../store/app/events';
import './ArticleTags.scss';

interface IArticleTags {
  tags: string[];
  isDetail: boolean;
}

export const ArticleTags = (props: IArticleTags) => {
  const {tags, isDetail} = props;
  const navigate = useNavigate();

  return (
    <div className="articleTags">
      {tags.map((item: string, i: number) => (
        <div
          className="articleTags-item"
          key={i}
          onClick={() => {
            setAuthor(null);
            setTag(item);
            isDetail && navigate(-1);
          }}
        >
          {item}
        </div>
      ))}
    </div>
  );
};
