import {Link} from 'react-router-dom';
import {FaqDataType} from '../types/coolOneTypes';
import {FaqList} from '../../FaqPage';

import './CoolOneFaq.scss';

interface IFaq {
  faqData: FaqDataType;
}

export const CoolOneFaq = (props: IFaq) => {
  const {faqData} = props;
  return (
    <div className="coolOneFaq">
      <div className="coolOneFaq-title">{faqData.faqTitle}</div>
      <FaqList faqData={faqData} />
      <Link to={'/cool-one/faq'} className="btn btn-secondary faq-button">
        {faqData.faqButtonText}
      </Link>
    </div>
  );
};
