import {useEffect} from 'react';
import axios from 'axios';
import {useLocation} from 'react-router';
import packageInfo from '../../../package.json';

interface ICacheBuster {
  children: any;
}

export const CacheBuster = (props: ICacheBuster) => {
  const {children} = props;

  const location = useLocation();
  const parseVersion = (str: string) => +str.replace(/\D/g, '');
  useEffect(() => {
    axios(`/meta.json?v=${+new Date()}`)
      .then((meta: any) => {
        if (meta.data.version) {
          const metaVersion = parseVersion(meta.data.version);
          const packageVersion = parseVersion(packageInfo.version);
          if (packageVersion < metaVersion) {
            alert('new version found');
            if (window?.location?.reload) {
              alert('reloading...');
              window.location.reload();
            }
          }
        }
      })
      .catch((error) => {
        console.error('something went wrong fetching meta.json', error);
      });
  }, [location]);

  return children;
};
