import {AppDomain} from './domain';
import {Navigation, SiteType} from './types';

export const setLanguage = AppDomain.createEvent<string>();
export const setNavIsOpen = AppDomain.createEvent<boolean>();
export const setIsMobile = AppDomain.createEvent<boolean>();
export const setIsLoading = AppDomain.createEvent<boolean>();
export const setNavIsOpened = AppDomain.createEvent<boolean>();
export const setLanguages = AppDomain.createEvent<any[] | null>();
export const setCurrentPage = AppDomain.createEvent<string>();
export const setTitle = AppDomain.createEvent<string>();
export const setDescription = AppDomain.createEvent<string>();
export const setSiteType = AppDomain.createEvent<SiteType>();
export const setNavigation = AppDomain.createEvent<Navigation>();
export const setAuthor = AppDomain.createEvent<string | null>();
export const setTag = AppDomain.createEvent<string | null>();
export const setModalIsOpened = AppDomain.createEvent<boolean>();
