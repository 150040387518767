import React from 'react';
import {Link} from 'react-router-dom';
import {CompanyInformationTeaserType, CompanyInformationHeroType} from '../types/companyInformationTypes';
import {settings} from '../../../common';
import {setModalIsOpened} from '../../../../store/app/events';

interface ITeasers {
  teasersData: CompanyInformationTeaserType[];
  heroData: CompanyInformationHeroType;
}

export const Teasers = (props: ITeasers) => {
  const {teasersData, heroData} = props;
  return (
    <section className="companyInformation">
      <div className="companyInformation-title">{heroData.heroTitle}</div>
      <div className="container">
        <div className="companyInformation-text">{heroData.heroText}</div>
        <div className="companyInformation-boxes">
          {teasersData.map((item: any, i: number) => (
            <div
              className="companyInformation-box fadeIn"
              style={{backgroundImage: `url(${settings.assetsURL}/${item.image[0]})`}}
            >
              <div className="divider shadow"></div>
              <img
                src={`${settings.assetsURL}/${item.image[0]}`}
                className="companyInformation-box-layer_bottom"
                alt=""
              />
              <div className="companyInformation-box-content">
                <div className="companyInformation-box-layer_top companyInformation-box-layer_slideleft">
                  <div className="companyInformation-box-text">
                    <div className="title">{item.title}</div>
                    <div className="text">
                      <div dangerouslySetInnerHTML={{__html: item.text}} />
                      <div className="companyInformationbox-button">
                        {!item.isContactButton ? (
                          <Link to={item.buttonLink} className="btn btn-2">
                            {item.buttonText}
                          </Link>
                        ) : (
                          <div className="btn btn-2 open-contact" onClick={() => setModalIsOpened(true)}>
                            {item.buttonText}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
