import React from 'react';
import {HeroType} from '../types/coolOneTypes';
import {settings} from '../../../common';

import './Hero.scss';
import {Link} from 'react-router-dom';
import {setModalIsOpened} from '../../../../store/app/events';

interface IHeroData {
  heroData: HeroType;
}

export const Hero = (props: IHeroData) => {
  const {heroData} = props;

  return (
    <div className="coolOneHero">
      <div className="container">
        <div className="coolOneHero-left">
          <div className="coolOneHero-left-title">{heroData.heroTitle}</div>
          <div className="coolOneHero-left-subtitle">{heroData.heroSubtitle}</div>
          <div className="coolOneHero-left-text">{heroData.heroText}</div>
          <div className="coolOneHero-left-text2">{heroData.heroText2}</div>
          {!heroData.isContactButton && heroData.heroButtonLink ? (
            <Link to={heroData.heroButtonLink} className="coolOneHero-left-button gm-btn gm-btn-primary gm-btn-hero">
              {heroData.heroButtonText}
            </Link>
          ) : (
            <div
              className="coolOneHero-left-button gm-btn gm-btn-primary gm-btn-hero"
              onClick={() => setModalIsOpened(true)}
            >
              {heroData.heroButtonText}
            </div>
          )}
        </div>
        <div className="coolOneHero-right fadeIn">
          <img
            src={`${settings.b2cConfig.assetsURL}/${heroData.heroImage}?width=800&quality=80&keepformat=true&format=png`}
            alt={heroData.heroTitle}
          />
        </div>
      </div>
    </div>
  );
};
