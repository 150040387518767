import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../common';

import {Teasers} from './';
import {MarketsTeaserType, MarketsHeroType} from './types/marketsTypes';
import './Markets.scss';
import {setTitle, setDescription} from '../../../store/app/events';

export const Markets = () => {
  const appStore = useStore($appStore);
  const [heroData, setHeroData] = useState<MarketsHeroType>();
  const [teasersData, setTeasersData] = useState<MarketsTeaserType[]>();

  useEffect(() => {
    const getMarkets = async (language: string) => {
      const data: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2bConfig.appName}/markets`,
        {},
        language,
      );
      setHeroData({
        heroTitle: data.items[0].data.heroTitle,
        heroText: data.items[0].data.heroText,
      });
      setTeasersData(data.items[0].data.teasers);
      setTitle(data.items[0].data.metaTitle);
      setDescription(data.items[0].data.metaDescription);
    };
    if (appStore.language !== null && appStore.siteType === 'b2b') {
      getMarkets(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);

  return (
    <div className="marketsPage fadeIn">
      {teasersData && heroData && !appStore.isLoading && <Teasers teasersData={teasersData} heroData={heroData} />}
    </div>
  );
};
