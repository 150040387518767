import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../common';
import {TermsDataType} from './types/termsType';
import './Terms.scss';
import {setTitle, setDescription} from '../../../store/app/events';

export const Terms = () => {
  const appStore = useStore($appStore);
  const [termsData, setTermsData] = useState<TermsDataType>();
  useEffect(() => {
    const getContact = async (language: string) => {
      const data: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2cConfig.appName}/termsandconditions`,
        {},
        language,
      );
      setTitle(data.items[0].data.metaTitle);
      setDescription(data.items[0].data.metaDescription);
      setTermsData(data.items[0].data);
    };

    if (appStore.language !== null && appStore.siteType === 'b2c') {
      getContact(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);

  return (
    <div className="termsPage">
      {termsData && (
        <div className="container">
          <div className="termsPage-header">
            <div className="container">
              <div className="termsPage-header-title">{termsData.title}</div>
            </div>
          </div>

          <div className="termsPage-text">
            <div className="container">
              <div className="text" dangerouslySetInnerHTML={{__html: termsData.text}} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
