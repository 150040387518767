import {FeatureTextType, ProductFeaturesType} from '../types/coolOneTypes';
import {settings} from '../../../common';

import './Features.scss';

interface IFeaturesData {
  productFeaturesData: ProductFeaturesType;
}

export const Features = (props: IFeaturesData) => {
  const {productFeaturesData} = props;

  return (
    <div className="features" id="benefits">
      <h2 dangerouslySetInnerHTML={{__html: productFeaturesData.productFeaturesTitle}} />
      <div className="container">
        <div className="left-image">
          <div className="cool-image">
            <div className="info-circles">
              <div className="info circle-1">
                <div className="left"></div>
                <div className="circle-info-content" dangerouslySetInnerHTML={{__html: productFeaturesData.sizeText}} />
                <div className="right"></div>
                <div className="line"></div>
              </div>
              <div className="info circle-2">
                <div className="left"></div>
                <div
                  className="circle-info-content"
                  dangerouslySetInnerHTML={{__html: productFeaturesData.weightText}}
                />

                <div className="right"></div>
                <div className="line"></div>
              </div>
              <div className="info circle-3">
                <div className="left"></div>
                <div
                  className="circle-info-content"
                  dangerouslySetInnerHTML={{__html: productFeaturesData.medicineChamberText}}
                />
                <div className="right"></div>
                <div className="line"></div>
              </div>
            </div>
            <img src={`${settings.b2cConfig.assetsURL}/${productFeaturesData.productFeaturesImage}`} alt="" />
          </div>
        </div>
        <div className="circle">
          <div className="circle-outer">
            <div className="circle-content">
              <div className="textList">
                {productFeaturesData.featureTexts.map((item: FeatureTextType, i: number) => (
                  <div className="textList-item" key={i}>
                    <div className="line-style-1">
                      <h3>{item.title}</h3>
                    </div>
                    <div className="line-style-2" dangerouslySetInnerHTML={{__html: item.text}} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
