import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../common';

import {AboutDataType, AwardsDataType, HeroDataType, ProblemSolutionDataType} from './types/ourStoryTypes';

import './OurStory.scss';
import {Link} from 'react-router-dom';
import {scrollToPosition} from '../../../utils/scrollToPosition';
import {setTitle, setDescription} from '../../../store/app/events';

export const OurStory = () => {
  const appStore = useStore($appStore);
  const [heroData, setHeroData] = useState<HeroDataType>();
  const [problemSolutionData, setProblemSolutionData] = useState<ProblemSolutionDataType>();
  const [aboutDataType, setAboutDataType] = useState<AboutDataType>();
  const [awardsDataType, setAwardsDataType] = useState<AwardsDataType>();

  useEffect(() => {
    const getOurStory = async (language: string) => {
      const response: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2cConfig.appName}/ourstory`,
        {},
        language,
      );
      const data = response.items[0].data;
      setTitle(response.items[0].data.metaTitle);
      setDescription(response.items[0].data.metaDescription);
      setHeroData({
        heroTitle: data.heroTitle,
        heroText: data.heroText,
        heroLinkText: data.heroLinkText,
      });
      setProblemSolutionData({
        problemTitle: data.problemTitle,
        problemText1: data.problemText1,
        problemText2: data.problemText2,
        solutionTitle: data.solutionTitle,
        solutionText1: data.solutionText1,
        solutionText2: data.solutionText2,
        solutionText3: data.solutionText3,
        solutionImage: data.solutionImage,
        solutionButtonText: data.solutionButtonText,
        solutionButtonLink: data.solutionButtonLink,
      });
      setAboutDataType({
        aboutTitle: data.aboutTitle,
        aboutSubtitle: data.aboutSubtitle,
        aboutLeadText: data.aboutLeadText,
        aboutColumn1Text: data.aboutColumn1Text,
        aboutColumn2Text: data.aboutColumn2Text,
        aboutColumn3Text: data.aboutColumn3Text,
        aboutFooterText: data.aboutFooterText,
        aboutImages: data.aboutImages,
        aboutLink: data.aboutLink,
      });
      setAwardsDataType({
        awardsTitle: data.awardsTitle,
        awardsImages: data.awardsImages,
      });
    };

    if (appStore.language !== null && appStore.siteType === 'b2c') {
      getOurStory(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);

  return (
    <div className="ourStory fadeIn">
      {heroData && (
        <div className="about-1">
          <div className="about-container">
            <h2>{heroData.heroTitle}</h2>
            <div className="subtitle" dangerouslySetInnerHTML={{__html: heroData.heroText}} />
            <div className="journeyLink" onClick={() => scrollToPosition('about-carla')}>
              {heroData.heroLinkText}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.1 8.6" width="13.1" height="8.6">
                <path d="M6.5,8.6c-0.4,0-0.8-0.2-1.1-0.5l-5-5.6c-0.6-0.6-0.5-1.6,0.1-2.1c0.6-0.5,1.6-0.5,2.1,0.1l3.9,4.4 l3.9-4.4c0.6-0.6,1.5-0.7,2.1-0.1c0.6,0.6,0.7,1.5,0.1,2.1l-5,5.6C7.4,8.4,7,8.6,6.5,8.6L6.5,8.6z"></path>
              </svg>
            </div>
          </div>
        </div>
      )}
      {problemSolutionData && (
        <div className="about-2 div-four">
          <div className="containter-four">
            <div className="four-left">
              <h3>{problemSolutionData.problemTitle}</h3>
              <h4 dangerouslySetInnerHTML={{__html: problemSolutionData.problemText1}} />
              <div dangerouslySetInnerHTML={{__html: problemSolutionData.problemText2}} />
            </div>
            <div className="four-right">
              <h3>{problemSolutionData.solutionTitle}</h3>
              <h4 dangerouslySetInnerHTML={{__html: problemSolutionData.solutionText1}} />
              <div dangerouslySetInnerHTML={{__html: problemSolutionData.solutionText2}} />
              <div>
                <img
                  src={`${settings.b2cConfig.assetsURL}/${problemSolutionData.solutionImage}`}
                  className="cool-about"
                  alt="Meds2Go Cool-One"
                />
                <div dangerouslySetInnerHTML={{__html: problemSolutionData.solutionText3}} />
                <Link
                  to={problemSolutionData.solutionButtonLink}
                  aria-label="More about Cool-One"
                  className="gm-btn gm-btn-primary gm-btn-learnmore"
                >
                  {problemSolutionData.solutionButtonText}
                </Link>
              </div>
            </div>
          </div>
          <div className="shadow-wrapper">
            <div className="divider shadow"></div>
          </div>
        </div>
      )}
      {aboutDataType && (
        <div className="about-4" id="about-carla">
          <div className="about-container">
            <h3>{aboutDataType.aboutTitle}</h3>
            <h4>{aboutDataType.aboutSubtitle}</h4>
            <h5>{aboutDataType.aboutLeadText}</h5>
            <div className="text">
              <div dangerouslySetInnerHTML={{__html: aboutDataType.aboutColumn1Text}}></div>
              <div dangerouslySetInnerHTML={{__html: aboutDataType.aboutColumn2Text}}></div>
              <div dangerouslySetInnerHTML={{__html: aboutDataType.aboutColumn3Text}}></div>
            </div>
            <div className="text-footer">
              <span>*</span>
              <Link to={aboutDataType.aboutLink}>
                <div dangerouslySetInnerHTML={{__html: aboutDataType.aboutFooterText}}></div>
              </Link>
            </div>
          </div>
        </div>
      )}
      {aboutDataType && aboutDataType.aboutImages && (
        <div className="about-5">
          {aboutDataType.aboutImages.map((item: string, i: number) => (
            <div style={{backgroundImage: `url(${settings.b2cConfig.assetsURL}/${item})`}}></div>
          ))}
        </div>
      )}
      {awardsDataType && (
        <div className="about-7">
          <div className="about-container">
            <h3>{awardsDataType.awardsTitle}</h3>
            <div className="awardsList">
              {awardsDataType.awardsImages.map((item: string, i: number) => (
                <img src={`${settings.b2cConfig.assetsURL}/${item}`} alt="" />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
