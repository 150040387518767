import React from 'react';
import {Link} from 'react-router-dom';
import {CoolingSolutionsTeaserType} from '../types/coolingSolutionsTypes';
import {settings} from '../../../common';
import {setModalIsOpened} from '../../../../store/app/events';

interface ITeasers {
  teasersData: CoolingSolutionsTeaserType[];
}

export const Teasers = (props: ITeasers) => {
  const {teasersData} = props;
  return (
    <div className="cooling-teasers">
      <div className="teasers container">
        {teasersData.map((item: any, i: number) => (
          <div className="teaser" key={i}>
            <div className="teaser-top">
              <div
                className="teaser-image fadeIn"
                style={{backgroundImage: `url(${settings.assetsURL}/${item.image[0]})`}}
              >
                <img src={`${settings.assetsURL}/${item.image[0]}`} alt={item.title} />
              </div>
              <div
                className="teaser-title"
                dangerouslySetInnerHTML={{
                  __html: item.title,
                }}
              />
            </div>
            <div className="teaser-content">
              <div className="teaser-texts">
                {item.text != null && <div className="teaser-text">{item.text}</div>}

                {item.dimensions != null && (
                  <div className="teaser-text">
                    <span>Dimensions</span>
                    <div className="description" dangerouslySetInnerHTML={{__html: item.dimensions}} />
                  </div>
                )}

                {item.weight != null && (
                  <div className="teaser-text">
                    <span>Weight</span>
                    <div className="description" dangerouslySetInnerHTML={{__html: item.weight}} />
                  </div>
                )}
                {item.coolingTime != null && (
                  <div className="teaser-text">
                    <span>Cooling time</span>
                    <div className="description" dangerouslySetInnerHTML={{__html: item.coolingTime}} />{' '}
                  </div>
                )}
              </div>
              <div className="teaser-button">
                {!item.isContactButton ? (
                  <Link to={item.buttonLink} className="btn btn-2">
                    {item.buttonText}
                  </Link>
                ) : (
                  <div
                    className="btn btn-2 open-contact"
                    data-modal="contact-modal"
                    onClick={() => setModalIsOpened(true)}
                  >
                    {item.buttonText}
                  </div>
                )}
              </div>
            </div>
            <div className="divider shadow"></div>
          </div>
        ))}
      </div>
    </div>
  );
};
