import {Link} from 'react-router-dom';
import {ArticleAuthor, ArticleShare, ArticleTags} from '..';
import {useStore, $appStore, getContent, settings} from '../../../common';

import {PostFlatDataType} from '../types/blogTypes';

import './Article.scss';

interface IArticle {
  article: PostFlatDataType;
}

export const Article = (props: IArticle) => {
  const {article} = props;
  const appStore = useStore($appStore);

  return (
    <div className="article fadeIn">
      <Link to={`/cool-one/blog/post/${article.slug}`} className="article-image fadeIn">
        <img
          src={`${settings.b2cConfig.assetsURL}/${article.image[0].slug}?height=208&quality=90`}
          alt={article.title}
        />
      </Link>
      <div className="article-body">
        <Link to={`/cool-one/blog/post/${article.slug}`} className="article-body-title">
          {article.title}
        </Link>
        <div className="article-body-short" dangerouslySetInnerHTML={{__html: article.shortDescription}} />
        <div className="article-tags">
          <ArticleTags tags={article.tags} isDetail={false} />
        </div>
      </div>
      <div className="article-footer">
        <ArticleAuthor author={article.author} isDetail={false} />
        <ArticleShare
          title={article.title}
          slug={article.slug}
          url={settings.siteUrl[appStore.language === 'en' ? 'en' : 'nl']}
        />
      </div>
    </div>
  );
};
