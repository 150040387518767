import React from 'react';
import {Link} from 'react-router-dom';
import {settings} from '../../common';
import {HeroType} from '../types/homeTypes';
import {scrollToPosition} from '../../../utils/scrollToPosition';

interface IHero {
  heroData: HeroType;
}

export const Hero = (props: IHero) => {
  const {heroData} = props;
  return (
    <section className="hero">
      <div className="container">
        <div className="hero-title">{heroData.heroTitle}</div>
        <div className="hero-subtitle">{heroData.heroSubtitle}</div>
        <div className="hero-images fadeIn">
          {heroData.heroImages.map((item: any, i: number) => (
            <div className="image" key={i}>
              <img src={`${settings.assetsURL}/${item.heroImage}`} alt="" />
            </div>
          ))}
        </div>
        <div className="hero-text" dangerouslySetInnerHTML={{__html: heroData.heroText}} />
        <div className="hero-button">
          {heroData.isContactButton ? (
            <Link to={heroData.buttonLink} id="scrollButton" className="scroll-to btn btn-primary">
              {heroData.buttonText}
            </Link>
          ) : (
            <div className="btn btn-primary" onClick={() => scrollToPosition('cold-chain')}>
              {heroData.buttonText}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
