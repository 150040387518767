export const blogGraphql = `queryBlogContents {
    flatData {
      metaTitle
      metaDescription
      title
      text
      featuredPostsText
      latestPostsText
      posts {
        flatData {
          author
          date
          slug
          image {
            slug
          }
          isActive
          isFeatured
          shortDescription
          tags
          title
        }
      }
    }
  }

    queryBlogitemsContents(filter: "data/isFeatured/iv eq true") {
    flatData {
          author
          date
          slug
          image {
            slug
          }
          isActive
          isFeatured
          shortDescription
          tags
          title
        }
  }
  `;
