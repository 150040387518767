import {useStore} from 'effector-react';
import {Link} from 'react-router-dom';
import $appStore from '../../store/app/store';
import {NavigationItem} from '../../store/app/types';

import './Footer.scss';
import {Newsletter} from '..';
export const Footer = () => {
  const appStore = useStore($appStore);

  return (
    <footer>
      <div className="footer-row-1">
        <div className="container">
          <div className="footer-info">
            <Link className="footer-logo" to="/">
              <svg xmlns="http://www.w3.org/2000/svg" width="230" height="58" viewBox="0 0 170 42.1">
                <path d="M6.1,32.7H0.1c-0.1-0.4-0.2-21-0.1-22.2c0.5-0.1,5.1-0.1,5.7,0v2.4C5.9,12.8,6,12.8,6,12.8 c1.3-1.7,3.1-2.6,5.2-2.9c1.6-0.2,3.2,0,4.7,0.7c1.2,0.6,2.1,1.4,2.8,2.5c0.1,0.1,0.1,0.2,0.2,0.3c0.3-0.4,0.6-0.7,0.9-1.1 c1.2-1.3,2.6-2.1,4.3-2.4c1.5-0.2,3-0.2,4.4,0.2c2.8,0.8,4.5,2.7,5.2,5.5c0.2,0.9,0.3,1.8,0.3,2.7c0,4.6,0,9.2,0,13.8 c0,0.2,0,0.3,0,0.6c-1.9,0-3.8,0-5.8,0v-0.8c0-4,0-7.9,0-11.9c0-0.5,0-1.1-0.1-1.6c-0.4-2.5-2.2-3.3-4.2-3.1c-2,0.2-3.4,1.6-3.6,3.6 c0,0.4-0.1,0.8-0.1,1.2c0,3.9,0,7.8,0,11.7v0.8h-6v-0.7c0-4,0-8,0-12c0-0.5,0-1-0.1-1.5c-0.5-2.4-2.3-3.2-4.2-3 c-2.2,0.2-3.7,1.9-3.7,4.2c0,1.2,0,2.4,0,3.6c0,2.9,0,5.9,0,8.9V32.7z"></path>
                <path d="M144.5,30.9c0,2.1-0.5,4.1-1.5,5.9c-1.1,1.9-2.7,3.4-4.7,4.2c-3.4,1.3-6.8,1.4-10.2,0.1 c-2.6-1-4.5-2.8-5.5-5.5c-0.2-0.5-0.3-1.1-0.5-1.7c1.9-0.5,3.7-1,5.5-1.5c0,0.1,0.1,0.2,0.1,0.3c0.6,2.3,2.3,3.8,4.7,3.9 c0.9,0.1,1.8,0,2.7-0.3c1.6-0.5,2.7-1.5,3.2-3.1c0.4-1.3,0.4-2.7,0.4-4.1c-0.1,0-0.2,0.1-0.2,0.1c-1.1,1.3-2.4,2-4,2.3 c-3.7,0.6-6.9-0.3-9.5-3c-1.5-1.5-2.3-3.4-2.6-5.4c-0.5-2.8-0.1-5.5,1.4-7.9c2.1-3.5,5.2-5.2,9.3-5.1c0.9,0,1.8,0.2,2.7,0.5 c1.1,0.4,2.1,1,2.9,2c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0v-2.3l5.8,0L144.5,30.9z M128.1,21c0,0.2,0,0.3,0,0.5 c0.1,2.8,1.9,4.8,4.7,5.1c2.3,0.2,4.4-0.9,5.3-3.2c0.5-1.3,0.6-2.6,0.3-3.9c-0.4-1.9-1.5-3.3-3.4-3.9c-1.9-0.6-3.7-0.3-5.2,1.1 C128.6,17.8,128.1,19.3,128.1,21"></path>
                <path d="M76.2,32.7c-0.1-0.8-0.2-1.5-0.2-2.2c-0.1,0.1-0.2,0.1-0.2,0.2c-1.3,1.6-3,2.4-5,2.6 c-3,0.3-5.7-0.5-8-2.5c-1.9-1.6-3-3.7-3.5-6.1c-0.7-3.1-0.4-6.2,1.1-9.1c1.6-3,4.1-4.9,7.5-5.5c1.6-0.3,3.1-0.2,4.7,0.2 c1.3,0.4,2.4,1,3.3,2.2c0.1-1,0-1.8,0-2.6c0-0.8,0-1.6,0-2.5V4.9V2.5V0h5.9c0.1,10.9-0.1,21.8,0.1,32.7H76.2z M75.9,21.4 c0-0.9-0.1-1.8-0.5-2.7c-0.8-2.1-2.6-3.4-4.9-3.4c-2.3,0-4.2,1.2-5,3.3c-0.8,2-0.8,4.1,0,6c0.9,2,2.5,3.1,4.7,3.2 c2.2,0.1,3.9-0.9,4.9-2.9C75.7,23.8,75.9,22.7,75.9,21.4"></path>
                <path d="M57.3,23.2h-7.9h-7.9c0.1,0.4,0.1,0.7,0.2,1c0.6,2.1,2.1,3.4,4.2,3.9c0.9,0.2,1.8,0.2,2.7,0 c1.5-0.3,2.6-1.2,3.3-2.5c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.1-0.2,0.2-0.3c0.5,0,4.2,1.1,5.1,1.4c-0.1,0.2-0.1,0.5-0.2,0.7 c-1.1,2.7-3,4.7-5.8,5.6c-3.2,1.1-6.4,1-9.4-0.6c-3.4-1.7-5.3-4.6-5.9-8.3c-0.4-2.4-0.3-4.8,0.7-7.1c1.5-3.7,4.2-6.1,8.2-6.8 c3.3-0.6,6.4,0,9.1,2.1c1.7,1.3,2.7,3.1,3.2,5.1c0.5,1.8,0.6,3.6,0.5,5.4C57.4,22.8,57.4,22.9,57.3,23.2 M51.4,19 c0.1-1.6-0.9-3.2-2.5-3.8c-0.9-0.4-1.8-0.5-2.8-0.4c-2.1,0.1-3.8,1.5-4.4,3.4c-0.1,0.3-0.1,0.5-0.2,0.9H51.4z"></path>
                <path d="M146.6,21.6c0-2.6,0.7-5,2.2-7.1c2-2.8,4.8-4.3,8.3-4.6c2.1-0.2,4.1,0.1,6.1,0.9 c3.7,1.7,5.9,4.5,6.6,8.5c0.5,2.7,0.2,5.4-1.1,7.9c-1.9,3.7-5,5.7-9.1,6.1c-2.7,0.3-5.2-0.2-7.5-1.6c-3-1.8-4.7-4.5-5.3-7.9	c-0.1-0.3-0.1-0.6-0.1-0.9C146.7,22.5,146.6,22,146.6,21.6 M163.9,21.6c0-0.8-0.1-1.7-0.4-2.5c-0.8-2.3-2.5-3.5-4.9-3.7 c-2.3-0.1-4.1,0.8-5.1,2.8c-1.2,2.3-1.2,4.8,0.1,7.1c1.1,1.9,2.8,2.8,5,2.7c2.2-0.1,3.7-1.2,4.7-3.2 C163.7,23.7,163.9,22.7,163.9,21.6"></path>
                <path d="M101.5,16.2c-1.7,0.4-3.4,0.8-5,1.1c-0.1-0.2-0.1-0.4-0.2-0.6c-0.4-1.3-1.5-2.2-2.8-2.4 c-0.6,0-1.2,0-1.8,0.1c-0.4,0.1-0.8,0.3-1.1,0.6c-1,0.9-0.8,2.4,0.4,3c0.6,0.3,1.2,0.4,1.8,0.5c1,0.2,1.9,0.4,2.9,0.6 c0.9,0.2,1.8,0.6,2.7,1.1c4,2.3,4,6.7,2.2,9.4c-1,1.5-2.3,2.5-3.9,3c-2.8,0.9-5.7,0.8-8.4-0.4c-2.3-1-3.8-2.8-4.4-5.2 c-0.1-0.2-0.1-0.4-0.1-0.7c1.7-0.4,3.4-0.8,5.2-1.2c0,0.2,0.1,0.3,0.1,0.5c0.4,1.6,1.6,2.7,3.2,2.9c0.8,0.1,1.6,0.1,2.4-0.2 c0.7-0.3,1.2-0.9,1.3-1.7c0.1-0.7-0.2-1.4-0.9-1.7c-0.5-0.3-1-0.5-1.6-0.6c-1.2-0.3-2.4-0.6-3.6-0.9c-1.2-0.3-2.3-0.8-3.2-1.6 c-2.5-2.1-3-5.8-1.1-8.6c1.3-1.9,3.2-3,5.4-3.4c2-0.4,4-0.2,5.9,0.6c2.4,0.9,3.9,2.6,4.5,5.1C101.4,15.8,101.4,16,101.5,16.2"></path>
                <path d="M111.8,14.3h3.6h3.6v4.4h-13.9c-0.1-3.2,0.7-5.9,3.4-7.8c1.1-0.8,2.4-1.5,3.5-2.2 c0.4-0.3,0.9-0.6,1.2-0.9c0.6-0.5,0.8-1.2,0.6-2c-0.1-0.3-0.2-0.6-0.4-0.8c-1-1-3-0.6-3.4,0.8c-0.1,0.5-0.2,0.9-0.1,1.4 c0.1,0.2,0.1,0.4,0.1,0.7h-4.7c-0.2-0.5-0.3-1.1-0.2-1.6c0.1-3.1,2.1-5.5,5.2-6.1c1.6-0.3,3.1-0.2,4.6,0.3c4.4,1.7,5.1,6.9,2.3,9.9 c-0.6,0.6-1.2,1.2-1.9,1.6c-1,0.6-2.1,1.3-3.1,1.9c-0.2,0.1-0.3,0.2-0.5,0.3C111.7,14.2,111.7,14.3,111.8,14.3"></path>
              </svg>
            </Link>
            <div className="footer-text">
              <div className="footer-address">
                Meds²go Travel B.V.
                <br />
                The Hague Tech Building
                <br />
                Wilhelmina van Pruisenweg
                <br />
                35 2595 AN Den Haag
                <br />
                The Netherlands
              </div>
              <div>
                <strong>KvK number:</strong> 67901913
                <br />
                <strong>BTW nummer:</strong> 857218980B01
              </div>
              <div className="footer-mail">
                <strong>E:</strong> <Link to="mailto:orders@meds2go.nl">orders@meds2go.nl</Link>
              </div>
              <div className="footer-phone">
                <strong>T:</strong> <Link to="tel:310624561043">31(0)624561043</Link>
              </div>
            </div>
          </div>

          <div className="footer-links">
            {appStore.navigation?.footerB2cMenu != null &&
              appStore.navigation?.footerB2cMenu?.length > 0 &&
              appStore.navigation.footerB2cMenu.map((item: NavigationItem, i: number) => (
                <Link className="footer-links-item" to={item.slug} key={i}>
                  {item.title}
                </Link>
              ))}
          </div>
          <div className="footer-links">
            {appStore.navigation?.footerMenu &&
              appStore.navigation?.footerMenu.map((item: NavigationItem, i: number) => (
                <Link className="footer-links-item" to={item.slug} key={i}>
                  {item.title}
                </Link>
              ))}
          </div>
          <Newsletter />
        </div>
      </div>
      <div className="footer-row-2">
        <div className="container">
          © 2023 meds²go All rights reserved -
          {appStore.navigation?.legalMenu.map((item: NavigationItem, i: number) => (
            <Link to={item.slug} key={i}>
              {item.title}
            </Link>
          ))}
        </div>
      </div>
    </footer>
  );
};
