import React, {useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../pages/common';

import {Formik, Form, Field} from 'formik';
import isEmail from 'validator/lib/isEmail';
import './Newsletter.scss';
import {label} from '../../utils/getLabel';

export const Newsletter = () => {
  const appStore = useStore($appStore);
  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const handleValidation = (values: any) => {
    let errors: any = {};

    if (!isEmail(values['email'])) {
      errors['email'] = label('error_email', appStore.language ? appStore.language : 'en');
    }

    return errors;
  };

  const onSubmit = (values: any, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
    getContent(
      appStore.siteType,
      'POST',
      `content/${settings.b2cConfig.appName}/newsletterform`,
      {
        email: {iv: values['email']},
      },
      '',
    );
    setSubmitting(false);
    setTimeout(() => {
      resetForm();
    }, 400);
    setIsFormSuccess(true);
    // setContactData(data.items[0].data);
  };
  return (
    <div className="newsletter">
      <div className="newsletter-form">
        <h4>{label('newsletter-title', appStore.language ? appStore.language : 'en')}</h4>
        <h5 className="no-br">
          <label htmlFor="newsletter-mail">
            {label('newsletter-subtitle', appStore.language ? appStore.language : 'en')}
          </label>
        </h5>
        <Formik
          initialValues={{firstName: '', lastName: '', email: ''}}
          validate={(values) => {
            return handleValidation(values);
          }}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            onSubmit(values, setSubmitting, resetForm);
          }}
        >
          {({errors, touched, handleChange, isSubmitting}) => (
            <Form id="footer-newsletter">
              <Field
                type="text"
                className="form-input"
                name="email"
                id="email"
                placeholder={label('newsletter-placeholder', appStore.language ? appStore.language : 'en')}
              />
              <button className="add-newsletter" type="submit" disabled={isSubmitting}>
                {label('newsletter-button', appStore.language ? appStore.language : 'en')}
              </button>
              {touched.email && errors.email && <p className="error-message">{errors.email}</p>}
            </Form>
          )}
        </Formik>
      </div>
      <div className="newsletter-social">
        <h4>{label('social-title', appStore.language ? appStore.language : 'en')}</h4>
        <div className="newsletter-social-list">
          <a href="https://www.facebook.com/Meds2Gotravel/" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.9 25.5" width="11" height="25">
              <path d="M7.9,25.5H2.6V12.8H0V8.4h2.6V5.7C2.6,2.1,4.1,0,8.4,0h3.5v4.4H9.7C8,4.4,7.9,5,7.9,6.2l0,.2h4 l-0.5,4.4H7.9V25.5z"></path>
            </svg>
          </a>
          <a href="https://www.instagram.com/meds_to_go/" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.5 25.6" width="25" height="25">
              <path d="M5.2,0h0.3c0.1,0,0.2,0.1,0.2,0.3v0.1c0,0.8,0,1.6,0,2.4c0,0.9,0,1.8,0,2.6c0,0.2,0,0.5,0,0.7 c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.4,0,0.6,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.1,0-0.2c0-0.3,0-0.6,0-0.8c0-1.6,0-3.2,0-4.8 c0-0.2,0-0.2,0.2-0.3h11.6c0,0,0.1,0,0.1,0c0.7,0,1.4,0,2.1,0c0.4,0,0.7,0,1,0.1c1.1,0.3,2,0.8,2.7,1.6c0.6,0.7,0.9,1.4,1.1,2.3 c0.1,0.5,0.1,0.9,0.1,1.4c0,0.9,0,1.8,0,2.7c0,0.1,0,0.2-0.2,0.2h-0.1c-2.5,0-5.1,0-7.6,0c-0.2,0-0.3,0-0.4-0.2 c-0.3-0.3-0.7-0.6-1.1-0.8c-1.4-0.8-2.9-1.1-4.5-0.8c-1.3,0.2-2.4,0.8-3.3,1.7C8.2,8.3,8,8.4,7.9,8.4c-2.6,0-5.1,0-7.7,0 C0,8.4,0,8.3,0,8.2C0,8,0,7.7,0,7.5c0-1,0-1.9,0-2.9c0-0.6,0.2-1.1,0.4-1.7c0.4-0.8,1-1.4,1.6-2c0,0,0,0,0,0c0,0,0,0,0,0 c0,1.5,0,3.1,0,4.6c0,0.2,0,0.4,0,0.6c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.4,0,0.6,0c0.1,0,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.2 c0-1.7,0-3.4,0-5.2c0,0,0-0.1,0-0.1c0-0.2,0-0.2,0.1-0.3c0.2-0.1,0.3-0.1,0.5-0.1c0.1,0,0.2,0,0.1,0.1c0,0,0,0.1,0,0.1 c0,1.6,0,3.1,0,4.7c0,0.3,0,0.7,0,1c0,0.1,0.1,0.2,0.2,0.2c0.2,0,0.4,0,0.6,0c0.2,0,0.2-0.1,0.3-0.3c0-0.1,0-0.2,0-0.3 C4.9,4.9,5,4,5,3c0-0.9,0-1.8,0-2.7C4.9,0.1,5,0.1,5.2,0 M18.9,4.3L18.9,4.3c0,0.5,0,0.9,0,1.4c0,0.5,0.3,0.8,0.8,0.8h2.7 c0.5,0,0.8-0.3,0.8-0.8V2.9c0-0.5-0.3-0.8-0.8-0.8c-0.9,0-1.8,0-2.7,0c-0.5,0-0.8,0.3-0.8,0.8C18.9,3.4,18.9,3.8,18.9,4.3"></path>
              <path d="M7.4,9.1c-1,1.5-1.4,3.1-1.1,4.8c0.3,1.5,0.9,2.8,2.1,3.8 c2.4,2,5.7,2.2,8.2,0.3c1.4-1.1,2.3-2.5,2.5-4.2c0.3-1.7-0.1-3.3-1.1-4.8h0.1h6.4c0.2,0,0.5,0,0.7,0c0,0,0.1,0,0.1,0 c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.3,0,0.4c0,1.1,0,2.1,0,3.2c0,2.6,0,5.2,0,7.8c0,1.3-0.5,2.4-1.3,3.3c-0.7,0.8-1.7,1.3-2.8,1.5 c-0.3,0.1-0.6,0.1-0.8,0.1c-5.2,0-10.4,0-15.6,0c-2.3,0-4.3-1.6-4.8-3.8C0.1,21.4,0,20.9,0,20.5v-7.2c0-1.2,0-2.5,0-3.7 c0-0.1,0-0.1,0-0.2c0-0.2,0.1-0.2,0.2-0.2c0.2,0,0.3,0,0.5,0c2.2,0,4.4,0,6.6,0H7.4z"></path>
              <path d="M12.8,7.9c2.8,0,5,2.3,5,5c0,2.8-2.3,5-5.1,5c-2.8,0-5-2.3-5-5 C7.7,10.2,10,7.9,12.8,7.9 M16.5,13c0-2.1-1.6-3.8-3.8-3.8C10.7,9.3,9,11,9.1,13c0.1,2.1,1.7,3.7,3.8,3.6 C14.9,16.6,16.5,14.9,16.5,13"></path>
            </svg>
          </a>
          <a href="https://twitter.com/Meds2Go_info" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27.4 22.3">
              <path d="M8.3,17.5c-2.9-0.1-4.8-2.2-5.2-3.9c0.8,0.2,1.6,0.1,2.4-0.1 c0,0,0,0,0.1,0c-1.6-0.4-2.8-1.2-3.7-2.6C1.3,10,1.1,9,1.1,7.9c0.8,0.4,1.6,0.7,2.5,0.7c-1.2-0.9-2-2-2.3-3.4C0.9,3.7,1.2,2.3,1.9,1 c3.1,3.6,6.9,5.6,11.6,5.9c0-0.2-0.1-0.4-0.1-0.6c-0.2-1.3,0.1-2.6,0.8-3.7c0.9-1.4,2.2-2.3,3.8-2.6C20-0.2,21.6,0.3,23,1.7 c0.1,0.1,0.2,0.1,0.3,0.1c1.2-0.3,2.2-0.7,3.3-1.3c0,0,0.1,0,0.1,0c0,0,0,0,0,0c-0.4,1.3-1.3,2.3-2.4,3.1c1.1-0.1,2.1-0.4,3.1-0.8 c0,0,0,0,0,0C27.2,3,27,3.3,26.8,3.5c-0.6,0.7-1.3,1.4-2,1.9c-0.1,0-0.1,0.1-0.1,0.2c0,0.8,0,1.7-0.1,2.5c-0.2,1.8-0.7,3.4-1.5,5 c-0.8,1.6-1.8,3.1-3.1,4.4c-2.1,2.2-4.7,3.7-7.8,4.3c-1,0.2-2.1,0.3-3.1,0.4c-3.3,0.1-6.3-0.7-9-2.4c0,0-0.1,0-0.1-0.1 c2,0.2,3.8,0,5.6-0.8C6.6,18.6,7.5,18.1,8.3,17.5"></path>
            </svg>
          </a>
          <a href="https://nl.linkedin.com/company/meds2go" target="_blank" rel="noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25px" height="24px" viewBox="0 0 31.6 31.5">
              <path d="M0.5,10.5h6.5v21H0.5V10.5z M3.8,0c2.1,0,3.8,1.7,3.8,3.8c0,2.1-1.7,3.8-3.8,3.8 C1.7,7.6,0,5.9,0,3.8C0,1.7,1.7,0,3.8,0"></path>
              <path d="M11.2,10.5h6.3v2.9h0.1c0.9-1.7,3-3.4,6.2-3.4c6.6,0,7.8,4.4,7.8,10v11.5H25V21.3 c0-2.4,0-5.6-3.4-5.6c-3.4,0-3.9,2.7-3.9,5.4v10.4h-6.5V10.5z"></path>
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};
