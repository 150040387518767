import React from 'react';
import {MeetListType, MeetTheCoolOneType} from '../types/coolOneTypes';
import {settings} from '../../../common';

import './MeetTheCoolOne.scss';
import {setModalIsOpened} from '../../../../store/app/events';
import {Link} from 'react-router-dom';

interface IMeetTheCoolOneData {
  meetTheCoolOneData: MeetTheCoolOneType;
}

export const MeetTheCoolOne = (props: IMeetTheCoolOneData) => {
  const {meetTheCoolOneData} = props;

  return (
    <div className="home-product">
      <h2>{meetTheCoolOneData.meetSectionTitle}</h2>
      <div className="home-product-cols">
        <div className="home-product-side"></div>
        <div className="home-product-container">
          <div className="home-product-image">
            <img
              src={`${settings.b2cConfig.assetsURL}/${meetTheCoolOneData.meetImage}`}
              alt={meetTheCoolOneData.meetSubtitle}
            />
          </div>
          <div className="home-product-texts">
            <h5>{meetTheCoolOneData.meetTitle}</h5>
            <h3>{meetTheCoolOneData.meetSubtitle}</h3>
            <p className="no-br">{meetTheCoolOneData.meetText}</p>
            <ul>
              {meetTheCoolOneData.meetList.map((item: MeetListType, i: number) => (
                <li>{item.text}</li>
              ))}
            </ul>
            {!meetTheCoolOneData.meetIsContactButton && meetTheCoolOneData.meetButtonLink ? (
              <Link to={meetTheCoolOneData.meetButtonLink} className="gm-btn gm-btn-primary gm-btn-hero open-contact">
                {meetTheCoolOneData.meetButtonText}
              </Link>
            ) : (
              <div
                className="gm-btn gm-btn-primary gm-btn-hero open-contact"
                data-modal="contact-modal"
                onClick={() => setModalIsOpened(true)}
              >
                {meetTheCoolOneData.meetButtonText}
              </div>
            )}
          </div>
        </div>
        <div className="home-product-side"></div>
      </div>
    </div>
  );
};
