import './ArticleDate.scss';

interface IArticleDate {
  date: Date;
}

export const ArticleDate = (props: IArticleDate) => {
  const {date} = props;

  const formatDate = (date: Date) => {
    return new Date(date)
      .toLocaleDateString('en-Us', {month: 'short', day: '2-digit', year: 'numeric'})
      .replace('.', '');
  };

  return <div className="articleDate">{formatDate(date)}</div>;
};
