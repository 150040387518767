import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../common';
import {CommunityDataType, TextItemType} from './types/communityType';
import './Community.scss';
import {CommunityForm} from '.';
import {setTitle, setDescription} from '../../../store/app/events';

export const Community = () => {
  const appStore = useStore($appStore);
  const [communityData, setCommunityData] = useState<CommunityDataType>();
  useEffect(() => {
    const getCommunity = async (language: string) => {
      const data: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2cConfig.appName}/communitypage`,
        {},
        language,
      );
      setTitle(data.items[0].data.metaTitle);
      setDescription(data.items[0].data.metaDescription);
      setCommunityData(data.items[0].data);
    };

    if (appStore.language !== null && appStore.siteType === 'b2c') {
      getCommunity(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);

  return (
    <div className="communityPage">
      {communityData && appStore.language && (
        <div id="information-community" className="cnt">
          <div className="info-title">
            <h1>{communityData.heroTitle}</h1>
            <div className="info-content" dangerouslySetInnerHTML={{__html: communityData.heroText}} />
          </div>
          <div className="community-image">
            <img
              alt=""
              uk-img=""
              src={`${settings.b2cConfig.assetsURL}/${communityData.heroImage}?width=1280&quality=90`}
            />
          </div>
          <div className="info-main-container">
            <div className="main-info">
              <div className="contact-info-cont">
                <div className="contact-details">
                  <h3 style={{textAlign: 'left'}}>{communityData.title}</h3>
                  <div style={{textAlign: 'left'}} className="text-1">
                    {communityData.subtitle}
                    <br />
                    <ul className="bulletList">
                      {communityData.text.map((item: TextItemType, i: number) => (
                        <li key={i}>{item.item}</li>
                      ))}
                    </ul>
                  </div>
                  <div style={{textAlign: 'left'}} className="text-2">
                    {communityData.formTitle}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="info-form">
            <CommunityForm communityData={communityData} />
          </div>
        </div>
      )}
    </div>
  );
};
