import {useStore} from 'effector-react';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {setNavIsOpen} from '../store/app/events';
import $appStore from '../store/app/store';

export default function ScrollToTop() {
  const location = useLocation();
  const appStore = useStore($appStore);

  useEffect(() => {
    window.scrollTo(0, 0);
    setNavIsOpen(false);
  }, [location.key, appStore.language, appStore.isLoading, appStore.currentPage]);

  return null;
}
