import React from 'react';
import {AboutTeamType, AboutTeamItemType} from '../types/aboutTypes';
import {settings} from '../../../common';

interface ITeam {
  teamData: AboutTeamType;
}

export const Team = (props: ITeam) => {
  const {teamData} = props;

  return (
    <section className="team">
      <div className="container">
        <div className="team-title">{teamData.teamTitle}</div>
        <div
          className="team-text"
          dangerouslySetInnerHTML={{
            __html: teamData.teamText,
          }}
        />
        <div className="team-boxes">
          {teamData.team.map((item: AboutTeamItemType, i: number) => (
            <div className="team-box" key={i}>
              <div className="team-image">
                <div style={{backgroundImage: `url(${settings.assetsURL}/${item.image[0]}`}}></div>
              </div>
              <div className="team-name">{item.name}</div>
              <div className="team-position">{item.position}</div>
              <div className="team-email">
                <a href={`mailto:${item.email}`}>{item.email}</a>
              </div>
              <div className="team-linkedin">
                <a href={item.linkedIn} target="_blank" rel="noreferrer"></a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
