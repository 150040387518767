import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../common';
import {setTitle, setDescription} from '../../../store/app/events';

import {Hero, Team, Teasers} from './';
import {AboutHeroType, AboutTeamType, AboutTeaserType} from './types/aboutTypes';

import './About.scss';

export const About = () => {
  const appStore = useStore($appStore);

  const [heroData, setHeroData] = useState<AboutHeroType>();
  const [teasersData, setTeasersData] = useState<AboutTeaserType[]>();
  const [teamData, setTeamData] = useState<AboutTeamType>();

  useEffect(() => {
    const getAbout = async (language: string) => {
      const data: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2bConfig.appName}/aboutus`,
        {},
        language,
      );
      setHeroData({
        heroTitle: data.items[0].data.heroTitle,
        heroText: data.items[0].data.heroText,
      });
      setTeasersData(data.items[0].data.teasers);
      setTeamData({
        teamTitle: data.items[0].data.teamTitle,
        teamText: data.items[0].data.teamText,
        team: data.items[0].data.team,
      });
      setTitle(data.items[0].data.metaTitle);
      setDescription(data.items[0].data.metaDescription);
    };
    if (appStore.language !== null && appStore.siteType === 'b2b') {
      getAbout(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);

  return (
    <div className="aboutPage">
      {heroData && <Hero heroData={heroData} />}
      {teasersData && <Teasers teasersData={teasersData} />}
      {teamData && <Team teamData={teamData} />}
    </div>
  );
};
