import React from 'react';
import {AboutTeaserType} from '../types/aboutTypes';
import {Link} from 'react-router-dom';
import {setModalIsOpened} from '../../../../store/app/events';

interface ITeasers {
  teasersData: AboutTeaserType[];
}

export const Teasers = (props: ITeasers) => {
  const {teasersData} = props;
  return (
    <section className="aboutTeasers">
      <div className="container">
        <div className="teaser-boxes">
          {teasersData.map((item: AboutTeaserType, i: number) => (
            <div className="teaser-box" key={i}>
              <div className="teaser-title">{item.title}</div>
              <div className="teaser-text">
                <div dangerouslySetInnerHTML={{__html: item.text}} />
                <h4 dangerouslySetInnerHTML={{__html: item.text2}} />
                <div dangerouslySetInnerHTML={{__html: item.text3}} />
              </div>
              <div className="teaser-button">
                {!item.isContactButton ? (
                  <Link to={item.buttonLink} className="btn btn-2">
                    {item.buttonText}
                  </Link>
                ) : (
                  <div
                    className="btn btn-2 open-contact"
                    data-modal="contact-modal"
                    onClick={() => setModalIsOpened(true)}
                  >
                    {item.buttonText}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
