import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../common';
import {blogGraphql} from './graphql/blogGraphql';
import {BlogType, PostFlatDataType, PostType} from './types/blogTypes';
import {Article, FeaturedArticle} from '.';
import {setAuthor, setDescription, setIsLoading, setTag, setTitle} from '../../../store/app/events';

import './Blog.scss';

export const Blog = () => {
  const appStore = useStore($appStore);
  const [blogData, setBlogData] = useState<BlogType>();
  const [articles, setArticles] = useState<PostType[]>();
  const [featuredArticle, setFeaturedArticle] = useState<PostFlatDataType>();

  useEffect(() => {
    const query = `{
  ${blogGraphql}
  }`;
    const getBlogPage = async (language: string) => {
      const data: any = await getContent(
        appStore.siteType,
        'POST',
        `content/${settings.b2cConfig.appName}/graphql`,
        {query},
        language,
      );
      setTitle(data.data.queryBlogContents[0].flatData.metaTitle);
      setDescription(data.data.queryBlogContents[0].flatData.metaDescription);
      setBlogData(data.data.queryBlogContents[0].flatData);
      setArticles(data.data.queryBlogContents[0].flatData.posts);
      setFeaturedArticle(data.data.queryBlogitemsContents[0].flatData);
    };

    if (appStore.language !== null && appStore.siteType === 'b2c') {
      getBlogPage(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);

  useEffect(() => {
    if (articles != null && blogData) {
      if (appStore.blogAuthor != null) {
        const filteredPosts = blogData.posts.filter((item: PostType) => item.flatData.author === appStore.blogAuthor);
        if (filteredPosts && filteredPosts.length > 0) {
          setIsLoading(true);
          setTimeout(() => {
            setArticles(filteredPosts);
            setIsLoading(false);
          }, 200);
        }
      }
    }
  }, [appStore.blogAuthor, blogData]);

  useEffect(() => {
    if (appStore.blogTag != null && blogData) {
      const filteredPosts = blogData.posts.filter(
        (item: PostType) => item.flatData.tags.filter((i: string) => i === appStore.blogTag).length > 0,
      );
      if (filteredPosts && filteredPosts.length > 0) {
        setIsLoading(true);
        setTimeout(() => {
          setArticles(filteredPosts);
          setIsLoading(false);
        }, 200);
      }
    }
  }, [appStore.blogTag, blogData]);

  useEffect(() => {
    if (appStore.blogAuthor === null && appStore.blogTag === null && articles !== blogData?.posts) {
      setIsLoading(true);
      setTimeout(() => {
        setArticles(blogData?.posts);
        setIsLoading(false);
      }, 200);
    }
  }, [appStore.blogAuthor, appStore.blogTag, articles, blogData?.posts]);

  return (
    <div className="blog fadeIn">
      {blogData && (
        <div className="blog-header">
          <div className="container">
            <div className="blog-header-title">{blogData.title}</div>
            <div
              className="blog-header-text"
              dangerouslySetInnerHTML={{
                __html: blogData.text,
              }}
            />
          </div>
        </div>
      )}
      {blogData && !appStore.isLoading && (
        <div className="container">
          <div className="blog-top">
            <div className="blog-headerTitle">{blogData.featuredPostsText}</div>
          </div>
          <div className="blog-articles">
            {featuredArticle && (
              <div className="blog-articles-featured">
                <FeaturedArticle featuredArticle={featuredArticle} />
              </div>
            )}
            <div className="blog-listHeader">
              <div className="blog-headerTitle">{blogData.latestPostsText}</div>
              {(appStore.blogAuthor !== null || appStore.blogTag !== null) && (
                <div
                  className="blog-reset"
                  onClick={() => {
                    setAuthor(null);
                    setTag(null);
                  }}
                >
                  Reset filters
                </div>
              )}
            </div>
            <div className="blog-list">
              {articles && articles.map((item: PostType, i: number) => <Article key={i} article={item.flatData} />)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
