import {Route, Routes} from 'react-router';
import {HelmetProvider} from 'react-helmet-async';

import ScrollToTop from './utils/ScrollToTop';

import {Layout, Seo, CacheBuster} from './components';

import {
  Home,
  ColdChain,
  Page,
  About,
  CoolingSolutions,
  Markets,
  CoolOne,
  OurStory,
  Contact,
  Blog,
  Community,
  Terms,
  FaqPage,
  BlogDetails,
  CompanyInformation,
} from './pages';

import {ReactComponent as UISvg} from './assets/ui.svg';

import './styles/main.scss';

function App() {
  return (
    <CacheBuster>
      <HelmetProvider>
        <div className="App">
          <Seo />
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path={'/'} element={<Home />} />
              <Route path={`/page/:slug`} element={<Page />} />
              <Route path={`/coldchain`} element={<ColdChain />} />
              <Route path={`/coldchain/about-us`} element={<About />} />
              <Route path={`/coldchain/cooling-solutions`} element={<CoolingSolutions />} />
              <Route path={`/coldchain/markets`} element={<Markets />} />
              <Route path={`/company-information`} element={<CompanyInformation />} />

              <Route path={`/cool-one`} element={<CoolOne />} />
              <Route path={`/cool-one/our-story`} element={<OurStory />} />
              <Route path={`/cool-one/contact`} element={<Contact />} />
              <Route path={`/cool-one/blog`} element={<Blog />} />
              <Route path={`/cool-one/blog/post/:slug`} element={<BlogDetails />} />
              <Route path={`/cool-one/community`} element={<Community />} />
              <Route path={`/cool-one/faq`} element={<FaqPage />} />
              <Route path={`/cool-one/terms-and-conditions`} element={<Terms />} />
            </Route>
          </Routes>
          <UISvg />
          {/* <Version /> */}
        </div>
      </HelmetProvider>
    </CacheBuster>
  );
}

export default App;
