import React, {useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../../../common';

import {Formik, Form, Field} from 'formik';
import isEmail from 'validator/lib/isEmail';

import {CommunityDataType} from '../types/communityType';
import {label} from '../../../../utils/getLabel';

interface ICommunityForm {
  communityData: CommunityDataType;
}

export const CommunityForm = (props: ICommunityForm) => {
  const {communityData} = props;
  const appStore = useStore($appStore);

  const [isFormSuccess, setIsFormSuccess] = useState(false);

  const handleValidation = (values: any) => {
    let errors: any = {};

    if (values['firstName'].length < 4) {
      errors['firstName'] = label('error_name', appStore.language ? appStore.language : 'en');
    }
    if (values['lastName'].length < 4) {
      errors['lastName'] = label('error_lname', appStore.language ? appStore.language : 'en');
    }
    if (!isEmail(values['email'])) {
      errors['email'] = label('error_email', appStore.language ? appStore.language : 'en');
    }

    return errors;
  };

  const onSubmit = (values: any, setSubmitting: (isSubmitting: boolean) => void, resetForm: () => void) => {
    getContent(
      appStore.siteType,
      'POST',
      `content/${settings.b2cConfig.appName}/communityform`,
      {
        firstName: {iv: values['firstName']},
        lastName: {iv: values['lastName']},
        email: {iv: values['email']},
      },
      '',
    );
    setSubmitting(false);
    setTimeout(() => {
      resetForm();
    }, 400);
    setIsFormSuccess(true);
    // setContactData(data.items[0].data);
  };

  return (
    <div className="info-form">
      {isFormSuccess && (
        <div className="communityForm-success">
          <div className="text" dangerouslySetInnerHTML={{__html: communityData.thankYouMessage}} />
        </div>
      )}
      {!isFormSuccess && (
        <Formik
          initialValues={{firstName: '', lastName: '', email: ''}}
          validate={(values) => {
            return handleValidation(values);
          }}
          onSubmit={(values, {setSubmitting, resetForm}) => {
            onSubmit(values, setSubmitting, resetForm);
          }}
        >
          {({errors, touched, handleChange, isSubmitting}) => (
            <Form id="frm-community" className="form -pt2">
              <div className="form-header">
                <div className="text-4">{communityData.formSubtitle}</div>
              </div>
              <div className="contact-fields">
                <div className="required col-30">
                  <Field
                    type="text"
                    className="form-input"
                    name="firstName"
                    id="firstName"
                    placeholder={communityData.firstName}
                  />
                  {touched.firstName && errors.firstName && <p className="error-message">{errors.firstName}</p>}
                </div>
                <div className="required col-30">
                  <Field
                    type="text"
                    className="form-input"
                    name="lastName"
                    id="lastName"
                    placeholder={communityData.lastName}
                  />
                  {touched.lastName && errors.lastName && <p className="error-message">{errors.lastName}</p>}
                </div>
                <div className="required col-30">
                  <Field type="text" className="form-input" name="email" id="email" placeholder={'EMAIL'} />
                  {touched.email && errors.email && <p className="error-message">{errors.email}</p>}
                </div>
                <div className="errors"></div>
                <div className="buttons">
                  <button className="" type="submit" disabled={isSubmitting}>
                    <div className="gm-btn gm-btn-primary gm-btn-hero">{communityData.buttonText}</div>
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
