import React, {useEffect, useState} from 'react';
import {useStore, $appStore, getContent, settings} from '../common';
import {setTitle, setDescription} from '../../store/app/events';
import {Hero, Teasers} from './';
import {HeroType, TeasersType} from './types/homeTypes';

import './Home.scss';

export const Home = () => {
  const appStore = useStore($appStore);
  const [heroData, setHeroData] = useState<HeroType>();
  const [teaserData, setTeaserData] = useState<TeasersType>();

  useEffect(() => {
    const getHomePage = async (language: string) => {
      const data: any = await getContent(
        appStore.siteType,
        'GET',
        `content/${settings.b2bConfig.appName}/home`,
        {},
        language,
      );
      setHeroData({
        heroTitle: data.items[0].data.heroTitle,
        heroSubtitle: data.items[0].data.heroSubtitle,
        heroText: data.items[0].data.heroText,
        buttonText: data.items[0].data.buttonText,
        buttonLink: data.items[0].data.buttonLink,
        isContactButton: data.items[0].data.isContactButton,
        heroImages: data.items[0].data.heroImages,
      });
      setTeaserData({
        sectionTeasersTitle: data.items[0].data.sectionTeasersTitle,
        teasers: data.items[0].data.teasers,
      });
      setTitle(data.items[0].data.metaTitle);
      setDescription(data.items[0].data.metaDescription);
    };
    if (appStore.language !== null && appStore.siteType === 'b2b') {
      getHomePage(appStore.language);
    }
  }, [appStore.language, appStore.siteType]);
  return (
    <div className="home fadeIn">
      {heroData && !appStore.isLoading && <Hero heroData={heroData} />}
      {teaserData && !appStore.isLoading && <Teasers teasers={teaserData} />}
    </div>
  );
};
